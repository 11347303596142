import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Stack,
  Alert,
  Snackbar,
} from '@mui/material';
import {
  fetchDeliveryStatsByStore,
  fetchDeliveryCountsByDateRange,
  fetchOrdersByTimeCategory,
  mapTimeCategoryToQueryParam,
} from '../controller/DriverEfficiencyController';
import { format } from 'date-fns';
import OrderDetailModal from '../components/OrderDetailModal';

const DriverEfficiencyView = () => {
  const [stats, setStats] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredStats, setFilteredStats] = useState(null);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryOrders, setCategoryOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentStoreId, setCurrentStoreId] = useState(null);

  const getStats = async () => {
    try {
      const data = await fetchDeliveryStatsByStore();
      setStats(data);
      // Get first store ID for default
      if (data && Object.keys(data).length > 0) {
        setCurrentStoreId(Object.keys(data)[0]);
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
      setError('Failed to fetch delivery statistics. Please try again later.');
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  const handleFilter = async () => {
    if (!startDate || !endDate) {
      setError('Please select both start and end dates');
      return;
    }

    try {
      setLoading(true);
      const data = await fetchDeliveryCountsByDateRange(
        format(startDate, 'yyyy-MM-dd'),
        format(endDate, 'yyyy-MM-dd')
      );
      setFilteredStats(data);

      // Update current store ID based on filtered data
      if (data && Object.keys(data).length > 0) {
        setCurrentStoreId(Object.keys(data)[0]);
      }
    } catch (error) {
      console.error('Error fetching delivery counts by date range:', error);
      setError('Failed to fetch filtered data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryClick = async (category, storeId) => {
    setSelectedCategory(category);
    setModalOpen(true);
    setCategoryOrders(null); // Reset previous data

    try {
      setLoading(true);
      const categoryParam = mapTimeCategoryToQueryParam(category);

      // Create request object according to our API structure
      const requestData = {
        timeCategory: categoryParam,
        storeId: parseInt(storeId),
      };

      // Add date range if available
      if (startDate && endDate) {
        requestData.startDate = format(startDate, 'yyyy-MM-dd');
        requestData.endDate = format(endDate, 'yyyy-MM-dd');
      }

      const orders = await fetchOrdersByTimeCategory(
        categoryParam,
        parseInt(storeId),
        startDate && format(startDate, 'yyyy-MM-dd'),
        endDate && format(endDate, 'yyyy-MM-dd')
      );

      setCategoryOrders(orders);
    } catch (error) {
      console.error('Error fetching orders by category:', error);
      setError(`Failed to fetch orders for ${category}. Please try again.`);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (setter) => (event) => {
    const dateValue = event.target.value;
    if (dateValue) {
      const [year, month, day] = dateValue.split('-');
      const date = new Date(Number(year), Number(month) - 1, Number(day));
      setter(date);
    } else {
      setter(null);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedCategory(null);
    setCategoryOrders(null);
  };

  const handleCloseError = () => {
    setError(null);
  };

  // Clickable card for filtered section
  const renderClickableCard = (title, count, color, storeId) => (
    <Card
      sx={{
        backgroundColor: color,
        color: 'white',
        flex: 1,
        cursor: 'pointer',
        transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
        },
      }}
      onClick={() => handleCategoryClick(title, storeId)}>
      <CardContent>
        <Typography variant='h6'>{title}</Typography>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='h4'>{count}</Typography>
          <Box
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '50%',
              width: 32,
              height: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Typography variant='body2' sx={{ fontSize: 18 }}>
              →
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );

  // Non-clickable card for all-time stats section
  const renderStatCard = (title, count, color) => (
    <Card
      sx={{
        backgroundColor: color,
        color: 'white',
        flex: 1,
      }}>
      <CardContent>
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='h4'>{count}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <Box
      sx={{ padding: 8, minHeight: '100vh', width: '100%', margin: '0 auto' }}>
      <Typography
        variant='h4'
        gutterBottom
        sx={{ fontWeight: 'bold', color: 'var(--h1)', paddingBottom: '30px' }}>
        Driver Efficiency Stats
      </Typography>

      <Stack direction='row' spacing={2} alignItems='center' marginBottom={4}>
        <TextField
          fullWidth
          label='Start Date'
          type='date'
          InputLabelProps={{ shrink: true }}
          value={startDate ? format(startDate, 'yyyy-MM-dd') : ''}
          onChange={handleDateChange(setStartDate)}
        />
        <TextField
          fullWidth
          label='End Date'
          type='date'
          InputLabelProps={{ shrink: true }}
          value={endDate ? format(endDate, 'yyyy-MM-dd') : ''}
          onChange={handleDateChange(setEndDate)}
        />
        <Button
          variant='contained'
          color='primary'
          onClick={handleFilter}
          disabled={loading}
          sx={{ height: '100%' }}>
          {loading ? 'Loading...' : 'Filter'}
        </Button>
      </Stack>

      {filteredStats && (
        <Box sx={{ marginBottom: 4 }}>
          <Typography
            variant='h5'
            sx={{ color: 'var(--h1)', fontWeight: 'bold', marginBottom: 2 }}>
            Comptes de livraison filtrée
          </Typography>
          <Stack
            direction='row'
            spacing={2}
            sx={{ width: '100%', display: 'block' }}>
            {Object.entries(filteredStats).map(([storeId, stats]) => (
              <Box key={storeId}>
                <Stack direction='row' spacing={4} sx={{ marginBottom: 2 }}>
                  <Typography variant='h5' sx={{ color: 'var(--h1)' }}>
                    Average Picking Time:{' '}
                    {Math.floor(stats.avg_picking_time / 60)}h{' '}
                    {stats.avg_picking_time % 60}m
                  </Typography>
                  <Typography variant='h5' sx={{ color: 'var(--h1)' }}>
                    Drivers actifs: {stats.unique_drivers}
                  </Typography>
                </Stack>
                <Stack direction='row' spacing={2}>
                  {renderClickableCard(
                    '1-20 Minutes',
                    stats.min_1_20,
                    '#4caf50',
                    storeId
                  )}
                  {renderClickableCard(
                    '21-40 Minutes',
                    stats.min_21_40,
                    '#2196f3',
                    storeId
                  )}
                  {renderClickableCard(
                    '41-60 Minutes',
                    stats.min_41_60,
                    '#ff9800',
                    storeId
                  )}
                  {renderClickableCard(
                    '60-90 Minutes',
                    stats.min_60_90,
                    '#e91e63',
                    storeId
                  )}
                  {renderClickableCard(
                    '90+ Minutes',
                    stats.min_90_plus,
                    '#f44336',
                    storeId
                  )}
                </Stack>
              </Box>
            ))}
          </Stack>
        </Box>
      )}
      <Typography
        variant='h5'
        sx={{
          color: 'var(--h1)',
          marginBottom: 2,
          fontWeight: 'bold',
        }}>
        Tous les comptes de livraison
      </Typography>

      {stats ? (
        Object.entries(stats).map(([storeId, storeStats]) => (
          <Box key={storeId} sx={{ marginBottom: 4 }}>
            {/* Display the average picking time at the top */}
            <Typography
              key={`picking-time-${storeId}`}
              variant='h5'
              sx={{ color: 'var(--h1)', paddingBottom: '30px' }}>
              Average Picking Time:{' '}
              {Math.floor(storeStats.avg_picking_time / 60)}h{' '}
              {storeStats.avg_picking_time % 60}m
            </Typography>

            <Stack spacing={2}>
              {/* First row: Time-based delivery statistics with clickable cards */}
              <Stack direction='row' spacing={2}>
                {renderStatCard('1-20 Minutes', storeStats.min_1_20, '#4caf50')}
                {renderStatCard(
                  '21-40 Minutes',
                  storeStats.min_21_40,
                  '#2196f3'
                )}
                {renderStatCard(
                  '41-60 Minutes',
                  storeStats.min_41_60,
                  '#ff9800'
                )}
                {renderStatCard(
                  '60-90 Minutes',
                  storeStats.min_60_90,
                  '#e91e63'
                )}
                {renderStatCard(
                  '90+ Minutes',
                  storeStats.min_90_plus,
                  '#f44336'
                )}
              </Stack>

              {/* Second row: Historical delivery statistics */}
              <Stack direction='row' spacing={2}>
                {renderStatCard(
                  'Last 30 Days',
                  storeStats.last_30_days,
                  '#3f51b5'
                )}
                {renderStatCard(
                  'Last 60 Days',
                  storeStats.last_60_days,
                  '#673ab7'
                )}
                {renderStatCard(
                  'Last 90 Days',
                  storeStats.last_90_days,
                  '#9c27b0'
                )}
                {renderStatCard(
                  'Last 120 Days',
                  storeStats.last_120_days,
                  '#e91e63'
                )}
              </Stack>
            </Stack>
          </Box>
        ))
      ) : (
        <Typography variant='body1' color='textSecondary'>
          Loading stats...
        </Typography>
      )}

      {/* Order Details Modal */}
      <OrderDetailModal
        open={modalOpen}
        onClose={handleCloseModal}
        orders={categoryOrders}
        category={selectedCategory}
      />

      {/* Error Snackbar */}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert
          onClose={handleCloseError}
          severity='error'
          sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DriverEfficiencyView;
