import React, { useState, useEffect } from 'react';
import {
  Paper,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Tooltip,
  Fab,
  styled,
  keyframes,
  Collapse,
} from '@mui/material';
import {
  Close,
  Mail,
  CrisisAlert,
  Campaign,
  Error,
  Help,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';

// Define wiggle animation
const wiggle = keyframes`
    0% { transform: translateX(0); }
    25% { transform: translateX(5px); }
    50% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
    100% { transform: translateX(0); }
`;

// Using styled with proper prop filtering
const DraggableListItem = styled(ListItem, {
  shouldForwardProp: (prop) =>
    !['isDragging', 'alertColor', 'isOverdue', 'isCritical'].includes(prop),
})(({ theme, isDragging, alertColor, isOverdue, isCritical }) => ({
  marginBottom: theme.spacing(1),
  border: `1px solid ${alertColor}`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'move',
  backgroundColor: isOverdue
    ? alertColor
    : isDragging
    ? theme.palette.action.hover
    : 'transparent',
  color: isOverdue ? '#fff' : 'inherit',
  transition: 'all 0.2s ease-in-out',
  animation: isCritical ? `${wiggle} 1s ease-in-out infinite` : 'none',
  '& .MuiListItemIcon-root': {
    minWidth: '40px',
  },
  '& .MuiTypography-root': {
    color: isOverdue ? '#fff' : 'inherit',
  },
  '&:last-child': {
    marginBottom: 0,
  },
  '&:hover': {
    backgroundColor: isOverdue ? alertColor : theme.palette.action.hover,
  },
}));

const NotificationText = styled('span')({
  fontFamily: 'monospace',
  fontStyle: 'italic',
});

const DetailsBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1.5),
  backgroundColor: 'rgba(0, 0, 0, 0.04)',
  borderRadius: theme.shape.borderRadius,
  fontSize: '0.85rem',
}));

const DragInstructions = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  fontStyle: 'italic',
  color: theme.palette.text.secondary,
  textAlign: 'center',
  marginTop: theme.spacing(1),
  padding: theme.spacing(0.5),
  borderTop: `1px dashed ${theme.palette.divider}`,
}));

const NotificationsPanel = ({
  notifications = [],
  onClose,
  isOpen,
  onOpen,
  onNotificationDragEnd,
}) => {
  const [draggedItem, setDraggedItem] = useState(null);
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
  const [timeElapsed, setTimeElapsed] = useState({});
  const [expandedItems, setExpandedItems] = useState({});

  const alertColors = {
    info: '#2196F3',
    warning: '#FF9800',
    urgent: '#f44336',
    error: '#f44336',
    default: '#757575',
  };

  const getAlertIcon = (type, isOverdue) => {
    const IconComponent =
      {
        info: Campaign,
        warning: Error,
        urgent: CrisisAlert,
        error: Help,
        default: Campaign,
      }[type.toLowerCase()] || Campaign;

    return (
      <IconComponent
        sx={{
          color: isOverdue
            ? '#fff'
            : alertColors[type.toLowerCase()] || alertColors.default,
          transition: 'color 0.2s ease-in-out',
        }}
      />
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const updated = {};

      notifications.forEach((notification) => {
        if (notification.timestamp) {
          const elapsed = Math.floor((now - notification.timestamp) / 1000);
          updated[notification.id] = elapsed;
        }
      });

      setTimeElapsed(updated);
    }, 1000);

    return () => clearInterval(interval);
  }, [notifications]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `[ ${minutes}m ${remainingSeconds}s ] `;
  };
  const isOverdue = (seconds) => seconds >= 120; // 2 minutes
  const isCritical = (seconds) => seconds >= 300; // 5 minutes

  const handleDragStart = (e, notification) => {
    setDraggedItem(notification);
    setDragPosition({
      x: e.clientX,
      y: e.clientY,
    });

    // Set drag data
    e.dataTransfer.setData('application/json', JSON.stringify(notification));
    e.dataTransfer.effectAllowed = 'move';

    // Create custom drag image
    const dragPreview = document.createElement('div');
    dragPreview.style.cssText = `
            position: absolute;
            top: -1000px;
            padding: 8px 12px;
            background: ${
              alertColors[notification.type] || alertColors.default
            };
            color: white;
            border-radius: 4px;
            font-weight: 500;
            box-shadow: 0 2px 8px rgba(0,0,0,0.2);
            max-width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            z-index: 9999;
        `;
    dragPreview.innerHTML = notification.title;

    document.body.appendChild(dragPreview);
    e.dataTransfer.setDragImage(dragPreview, 10, 10);

    // Remove the preview element after it's been used
    setTimeout(() => {
      document.body.removeChild(dragPreview);
    }, 0);
  };

  const handleDrag = (e) => {
    if (!e.clientX && !e.clientY) return; // Sometimes drag events fire with no coordinates
    e.preventDefault();
  };

  const handleDragEnd = (e) => {
    if (draggedItem) {
      const dropPosition = {
        x: e.clientX - dragPosition.x,
        y: e.clientY - dragPosition.y,
      };

      if (onNotificationDragEnd) {
        onNotificationDragEnd({
          notification: draggedItem,
          position: dropPosition,
          event: e,
        });
      }

      setDraggedItem(null);
      setDragPosition({ x: 0, y: 0 });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const toggleExpandItem = (id) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <Box sx={{ position: 'relative' }} onDragOver={handleDragOver}>
      {isOpen ? (
        <Paper
          elevation={6}
          sx={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            width: '350px',
            maxHeight: '80vh',
            overflow: 'auto',
            zIndex: 9999,
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
          }}>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
              }}>
              <Typography variant='h6' component='div'>
                Alertes ({notifications.length})
              </Typography>
              <IconButton
                size='small'
                onClick={onClose}
                sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}>
                <Close />
              </IconButton>
            </Box>

            {notifications.length === 0 ? (
              <Typography
                sx={{ textAlign: 'center', py: 2, color: 'text.secondary' }}>
                Aucune Alerte
              </Typography>
            ) : (
              <List sx={{ p: 0 }}>
                {notifications.map((alert) => {
                  const elapsed = timeElapsed[alert.id] || 0;
                  const isExpanded = expandedItems[alert.id] || false;

                  return (
                    <DraggableListItem
                      key={alert.id}
                      draggable='true'
                      onDragStart={(e) => handleDragStart(e, alert)}
                      onDrag={handleDrag}
                      onDragEnd={handleDragEnd}
                      isDragging={draggedItem?.id === alert.id}
                      alertColor={
                        alertColors[alert.type] || alertColors.default
                      }
                      isOverdue={isOverdue(elapsed)}
                      isCritical={isCritical(elapsed)}
                      sx={{
                        position: 'relative',
                        flexDirection: 'column',
                        alignItems: 'stretch',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'flex-start',
                        }}>
                        <ListItemIcon sx={{ minWidth: '40px', mt: 0.5 }}>
                          {getAlertIcon(alert.type, isOverdue(elapsed))}
                        </ListItemIcon>

                        <ListItemText
                          primary={
                            <Typography
                              lineHeight={1.25}
                              variant='subtitle1'
                              component='div'>
                              <b>{formatTime(elapsed)}</b>
                              {alert.title}
                            </Typography>
                          }
                          secondary={
                            <Tooltip title='Alert' placement='bottom-start'>
                              <NotificationText>{alert.text}</NotificationText>
                            </Tooltip>
                          }
                          sx={{ flex: 1 }}
                        />

                        {alert.products && alert.products.length > 0 && (
                          <IconButton
                            size='small'
                            edge='end'
                            onClick={() => toggleExpandItem(alert.id)}
                            sx={{ ml: 1 }}>
                            {isExpanded ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        )}
                      </Box>

                      {alert.products && alert.products.length > 0 && (
                        <Collapse in={isExpanded} timeout='auto' unmountOnExit>
                          <DetailsBox>
                            <Typography
                              variant='subtitle2'
                              component='div'
                              sx={{ mb: 1 }}>
                              Produits:
                            </Typography>
                            <List dense disablePadding>
                              {alert.products.map((product, idx) => (
                                <ListItem
                                  key={idx}
                                  disablePadding
                                  sx={{ py: 0.5 }}>
                                  <ListItemText
                                    primary={product.item}
                                    secondary={`${product.units} unités`}
                                    primaryTypographyProps={{
                                      variant: 'body2',
                                    }}
                                    secondaryTypographyProps={{
                                      variant: 'caption',
                                    }}
                                  />
                                </ListItem>
                              ))}
                            </List>
                            <DragInstructions>
                              Glisser sur un conducteur pour assigner
                            </DragInstructions>
                          </DetailsBox>
                        </Collapse>
                      )}
                    </DraggableListItem>
                  );
                })}
              </List>
            )}
          </Box>
        </Paper>
      ) : (
        <Tooltip title='Notification'>
          <Fab
            color='primary'
            onClick={onOpen}
            sx={{
              position: 'fixed',
              top: '20px',
              right: '20px',
              zIndex: 9999,
            }}>
            <Mail />
          </Fab>
        </Tooltip>
      )}
    </Box>
  );
};

export default NotificationsPanel;
