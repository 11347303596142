import React from 'react';
import { TableCell, Button } from '@mui/material';

const ClickableStatsCell = ({
  value,
  onClick,
  color = '#f57c00',
  align = 'right',
}) => {
  if (!value || value === 0) {
    return (
      <TableCell align={align} style={{ fontWeight: '500', color }}>
        {value || 0}
      </TableCell>
    );
  }

  return (
    <TableCell align={align} style={{ fontWeight: '500' }}>
      <Button
        onClick={onClick}
        variant='text'
        size='small'
        style={{
          color,
          fontWeight: '500',
          minWidth: 'auto',
          padding: '2px 8px',
          textTransform: 'none',
          fontSize: 'inherit',
        }}>
        {value}
      </Button>
    </TableCell>
  );
};

export default ClickableStatsCell;
