import { getStore } from '../components/GetStore';

class LocationMapController {
    constructor() {
        this.apiUrl = process.env.REACT_APP_API_URL;
        this.apiKey = 'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj';
    }

    async getDriversLocation(storeId) {
        try {
            const response = await fetch(`${this.apiUrl}/misc_dispatch/v2/get_drivers_location/${storeId}`, {
                headers: {
                    'X-Dispatch-key': this.apiKey,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to fetch driver locations');
            }

            const data = await response.json();
            return {
                location: data.location,
                pickup: data.pickup,
                notifications: data.pickup ? data.pickup.map(pickup => ({
                    id: pickup.id,
                    title: "Pickup chez " + pickup.name,
                    text: `Requête de ${pickup.created_by}`,
                    type: "urgent",
                    timestamp: new Date(pickup.created_at),
                    products: JSON.parse(pickup.products)
                })) : []
            };
        } catch (error) {
            throw new Error('Error fetching driver data: ' + error.message);
        }
    }

    async createPickupOrder(driverId, transferId) {
        try {
            const response = await fetch(`${this.apiUrl}/misc_dispatch/v2/create_pickup_order`, {
                method: 'POST',
                headers: {
                    'X-Dispatch-key': this.apiKey,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'driver_id': driverId,
                    'transfer_id': transferId
                })
            });

            if (!response.ok) {
                throw new Error('Failed to assign pickup');
            }

            return await response.json();
        } catch (error) {
            throw new Error('Error creating pickup order: ' + error.message);
        }
    }
    
    // Fetch route information for a list of routes
    getOrderRoutePath = async (order) => {
        const store = getStore();
        
        const headers = {
            'Content-Type': 'application/json',
            'X-Dispatch-key': this.apiKey,
        };
    
        try {
            const response = await fetch(
                `${this.apiUrl}/misc_dispatch/v2/get_order_telemetry/${store}/${order}`,
                {
                    headers,
                }
            );
        
            if (!response.ok) {
                throw new Error('Failed to fetch route data');
            }

            const data = await response.json()
    
            return data[0];
        } catch (error) {
            console.error('Error fetching route info:', error);
            throw new Error('Error fetching route info');
        }
    };
}

export default new LocationMapController();