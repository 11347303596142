import React, { useCallback, useEffect, useState, useRef } from 'react';
import LocationMapController from '../controller/LocationMapController';
import _ from 'lodash';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

export const RouteMap = ({ orderId, open, onClose }) => {
    // State declarations
    const [map, setMap] = useState(null);
    const [coordinates, setCoordinates] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Refs
    const mapRef = useRef(null);
    const mapInitializedRef = useRef(false);

    // Utility function to calculate distance between two points
    const calculateDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Earth's radius in kilometers
        const dLat = (lat2 - lat1) * Math.PI / 180;
        const dLon = (lon2 - lon1) * Math.PI / 180;
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    // Function to filter outliers
    const filterOutliers = useCallback((coords) => {
        if (!coords || coords.length < 3) return coords;

        // Sort coordinates by timestamp
        const sortedCoords = _.sortBy(coords, 'created_at');

        // First, deduplicate coordinates
        const uniqueCoords = _.uniqWith(sortedCoords, (coordA, coordB) =>
            coordA.latitude === coordB.latitude &&
            coordA.longitude === coordB.longitude
        );

        const filtered = uniqueCoords.filter((coord, index, array) => {
            if (index === 0 || index === array.length - 1) return true;

            const prev = array[index - 1];
            const next = array[index + 1];

            // Calculate distances to previous and next points
            const distToPrev = calculateDistance(
                coord.latitude, coord.longitude,
                prev.latitude, prev.longitude
            );
            const distToNext = calculateDistance(
                coord.latitude, coord.longitude,
                next.latitude, next.longitude
            );

            // Calculate time differences in seconds
            const timeToPrev = (new Date(coord.created_at) - new Date(prev.created_at)) / 1000;
            const timeToNext = (new Date(next.created_at) - new Date(coord.created_at)) / 1000;

            // Avoid division by zero
            if (timeToPrev <= 0 || timeToNext <= 0) return false;

            // Calculate speeds in km/h
            const speedFromPrev = (distToPrev / timeToPrev) * 3600;
            const speedToNext = (distToNext / timeToNext) * 3600;

            // Filter based on reasonable speed limits and distances
            return (
                speedFromPrev < 120 &&
                speedToNext < 120 &&
                distToPrev < 4 &&
                distToNext < 4 &&
                Math.abs(speedFromPrev - speedToNext) < 50
            );
        });

        return filtered;
    }, []);

    // Cleanup function for map
    const cleanupMap = useCallback(() => {
        if (map) {
            try {
                map.remove();
            } catch (e) {
                console.error('Error removing map:', e);
            }
            setMap(null);
        }
        mapInitializedRef.current = false;
    }, [map]);

    // Fetch route data
    useEffect(() => {
        const fetchRouteData = async () => {
            try {
                setLoading(true);
                setError(null);
                const routeData = await LocationMapController.getOrderRoutePath(orderId);

                // Handle nested array structure if present
                const flattenedData = Array.isArray(routeData[0]) ? routeData[0] : routeData;

                const filteredData = filterOutliers(flattenedData);

                // Ensure the data has valid latitude/longitude
                const validCoordinates = filteredData.filter(coord =>
                    typeof coord.latitude === 'number' &&
                    typeof coord.longitude === 'number' &&
                    !isNaN(coord.latitude) &&
                    !isNaN(coord.longitude)
                );

                if (validCoordinates.length < 2) {
                    setError('Not enough valid coordinates to display a route');
                    return;
                }

                setCoordinates(validCoordinates);
                console.log('Filtered and validated route data:', validCoordinates);
            } catch (err) {
                console.error('Failed to fetch route:', err);
                setError(err.message || 'Failed to load route data');
            } finally {
                setLoading(false);
            }
        };


        if (orderId && open) {
            fetchRouteData();
        }

        return () => {
            if (!open) {
                cleanupMap();
            }
        };
    }, [orderId, open, filterOutliers, cleanupMap]);

    // Initialize map when dialog opens
    useEffect(() => {
        // Only initialize map when dialog is open and we have coordinates
        if (!open || !mapRef.current || mapInitializedRef.current || map || !coordinates || coordinates.length < 2) {
            return;
        }
    
        // Ensure Leaflet script is loaded
        const loadLeaflet = () => {
            return new Promise((resolve, reject) => {
                if (window.L) {
                    resolve(window.L);
                    return;
                }
    
                // Load Leaflet CSS
                const link = document.createElement('link');
                link.rel = 'stylesheet';
                link.href = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.css';
                document.head.appendChild(link);
    
                // Load Leaflet JS
                const script = document.createElement('script');
                script.src = 'https://unpkg.com/leaflet@1.9.4/dist/leaflet.js';
                script.onload = () => resolve(window.L);
                script.onerror = () => reject(new Error('Failed to load Leaflet'));
                document.head.appendChild(script);
            });
        };
    
        // Create map
        const initializeMap = async () => {
            try {
                const L = await loadLeaflet();
    
                if (!mapRef.current || mapInitializedRef.current || map) return;
    
                mapInitializedRef.current = true;
    
                // Calculate initial view based on coordinates if available
                let initialView = [45.4877, -73.3844]; // Default
                let initialZoom = 13; // Default
                
                if (coordinates && coordinates.length > 0) {
                    initialView = [coordinates[0].latitude, coordinates[0].longitude];
                }
    
                const mapInstance = L.map(mapRef.current, {
                    minZoom: 5,
                    maxZoom: 18
                }).setView(initialView, initialZoom);
    
                L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
                }).addTo(mapInstance);
    
                setMap(mapInstance);
                
                // Make sure the map is properly sized
                setTimeout(() => {
                    mapInstance.invalidateSize();
                    
                    // If we have coordinates, draw the route immediately
                    if (coordinates && coordinates.length >= 2) {
                        const routeCoords = coordinates.map(coord => [coord.latitude, coord.longitude]);
                        
                        const routeLine = L.polyline(routeCoords, {
                            color: 'blue',
                            weight: 3,
                            opacity: 0.7
                        }).addTo(mapInstance);
                        
                        // Add start and end markers
                        L.marker(routeCoords[0], { title: 'Start' }).addTo(mapInstance);
                        L.marker(routeCoords[routeCoords.length - 1], { title: 'End' }).addTo(mapInstance);
                        
                        // Fit bounds to show the entire route
                        const bounds = new L.LatLngBounds(routeCoords);
                        if (bounds.isValid()) {
                            mapInstance.fitBounds(bounds, { padding: [50, 50] });
                        }
                    }
                }, 500);
            } catch (err) {
                console.error('Error initializing map:', err);
                setError('Failed to initialize map: ' + (err.message || ''));
                mapInitializedRef.current = false;
            }
        };
    
        // Allow time for the dialog to fully render
        const timer = setTimeout(() => {
            if (mapRef.current) {
                initializeMap();
            }
        }, 500);
    
        return () => {
            clearTimeout(timer);
        };
    }, [open, map, coordinates]);

    // Update route on map when coordinates change
    useEffect(() => {
        if (!map || !coordinates || coordinates.length < 2) {
            console.log(map,coordinates)
            return;
        
        };

        try {
            // Remove previous route layers
            map.eachLayer(layer => {
                if (layer instanceof window.L.Polyline || layer instanceof window.L.Marker) {
                    map.removeLayer(layer);
                }
            });

            const routeCoords = coordinates.map(coord => [coord.latitude, coord.longitude]);

            const routeLine = window.L.polyline(routeCoords, {
                color: 'blue',
                weight: 3,
                opacity: 0.7
            }).addTo(map);

            // Add start and end markers
            window.L.marker(routeCoords[0], { title: 'Start' }).addTo(map);
            window.L.marker(routeCoords[routeCoords.length - 1], { title: 'End' }).addTo(map);

            // Fit bounds and resize the map
            const bounds = new window.L.LatLngBounds(routeCoords);
            if (bounds.isValid()) {
                map.fitBounds(bounds, { padding: [50, 50] });
            } else {
                map.setView(routeCoords[0], 12); // Ensure proper zoom
            }

            map.invalidateSize();
        } catch (error) {
            console.error('Error updating route:', error);
            setError('Failed to display route: ' + (error.message || ''));
        }
    }, [map, coordinates]);


    // Handle dialog resize
    useEffect(() => {
        if (open && map) {
            const handleResize = () => {
                map.invalidateSize();
            };

            window.addEventListener('resize', handleResize);

            // Initial resize
            const timer = setTimeout(handleResize, 300);

            return () => {
                window.removeEventListener('resize', handleResize);
                clearTimeout(timer);
            };
        }
    }, [open, map]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            cleanupMap();
        };
    }, [cleanupMap]);

    const styles = {
        dialogContent: {
            padding: 0,
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
        },
        closeButton: {
            position: 'absolute',
            top: 16,
            right: 16,
            zIndex: 1000,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)'
            }
        },
        mapContainer: {
            position: 'relative',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100%'
        },
        loadingOverlay: {
            position: 'absolute',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            zIndex: 900
        },
        errorMessage: {
            position: 'absolute',
            top: 16,
            left: 16,
            right: 16,
            zIndex: 900,
            backgroundColor: '#FEF2F2',
            color: '#EF4444',
            padding: '8px',
            borderRadius: '4px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        },
        mapElement: {
            width: '100%',
            height: '100%',
            minHeight: '500px'
        }
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={onClose}
            TransitionProps={{
                timeout: {
                    enter: 500,
                    exit: 400,
                }
            }}
        >
            <DialogContent style={styles.dialogContent}>
                <IconButton
                    onClick={onClose}
                    style={styles.closeButton}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>

                <div style={styles.mapContainer}>
                    {loading && (
                        <div style={styles.loadingOverlay}>
                            Loading route data...
                        </div>
                    )}

                    {error && (
                        <div style={styles.errorMessage}>
                            Error: {error}
                        </div>
                    )}

                    <div
                        ref={mapRef}
                        style={styles.mapElement}
                        id="route-map-container"
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default RouteMap;