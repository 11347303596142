import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    Paper,
    IconButton,
    Stack,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Pagination,
    CircularProgress
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {getStore} from '../components/GetStore'

const Transfers = () => {
    const [pickupsLocations, setPickupsLocations] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [pickupsList, setPickupsList] = useState([]);
    const itemsPerPage = 10;
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedPickup, setSelectedPickup] = useState('');
    const [po, setPo] = useState('');
    const [partsList, setPartsList] = useState([
        { item: '', units: 1 }
    ]);
    const stores = ['St-Hubert','St-Jean','Châteauguay']

    useEffect(() => {
        const fetchPickupLocations = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/misc_dispatch/v2/get_pickup_locations`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Dispatch-key': 'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj',
                    },
                });
                
                if (!response.ok) {
                    throw new Error('Failed to fetch pickupsLocations');
                }

                const data = await response.json();
                setPickupsLocations(data);
                setError(null);
            } catch (err) {
                setError('Error loading pickupsLocations. Please try again later.');
                console.error('Error fetching pickupsLocations:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchPickupLocations();
    }, []);

    const fetchPickups = async () => {
        try {
            const storeId = getStore();
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/misc_dispatch/v2/get_pickups?page=${page}&items_per_page=${itemsPerPage}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Dispatch-key': 'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj',
                    },
                }
            );
            
            if (!response.ok) {
                throw new Error('Failed to fetch pickups');
            }

            const data = await response.json();
            setPickupsList(data.pickups);
            setTotalPages(data.total_pages);
            setError(null);
        } catch (err) {
            setError('Error loading pickups. Please try again later.');
            console.error('Error fetching pickups:', err);
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchPickups();
    }, [page]);

    const handleAddPart = () => {
        setPartsList((prevList) => [...prevList, { item: '', units: 1 }]);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleRemovePart = (indexToRemove) => {
        setPartsList((prevList) => prevList.filter((_, index) => index !== indexToRemove));
    };

    const handlePartChange = (index, field, value) => {
        setPartsList((prevList) => {
            const newList = [...prevList];
            newList[index] = {
                ...newList[index],
                [field]: field === 'units' ? Math.max(1, value) : value
            };
            return newList;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedPickup || partsList.some(part => !part.item.trim())) {
            return;
        }

        const formData = {
            pickupId: selectedPickup,
            sender: po,
            parts: partsList.map(part => ({
                item: part.item.trim(),
                units: part.units
            }))
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/misc_dispatch/v2/set_pickup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Dispatch-key': 'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Commande envoyée avec succès');
                setSelectedPickup('');
                setPartsList([{ item: '', units: 1 }]);
                fetchPickups();
            } else {
                console.error('Échec de l\'envoi de la commande');
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi de la commande:', error);
        }
    };

    return (
        <Container maxWidth="md">
            <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                <Typography variant="h4" component="h2" gutterBottom>
                    Formulaire de Commande de Pièces
                </Typography>

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel id="pickup-select-label">Sélectionner un Magasin</InputLabel>
                        <Select
                            labelId="pickup-select-label"
                            id="pickup-select"
                            value={selectedPickup}
                            label="Sélectionner un Magasin"
                            onChange={(e) => setSelectedPickup(e.target.value)}
                            disabled={loading}
                        >
                            <MenuItem value="">
                                <em>Sélectionner un magasin</em>
                            </MenuItem>
                            {pickupsLocations.map((pickup) => (
                                <MenuItem key={pickup.id} value={pickup.id}>
                                    {pickup.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <br />
                        <TextField
                                    fullWidth
                                    label="PO"
                                    id="po-input"
                                    value={po}
                                    onChange={(e) => setPo(e.target.value)}
                                />
                        {error && (
                            <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                                {error}
                            </Typography>
                        )}
                    </FormControl>

                    <Box sx={{ mb: 3 }}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                            <Typography variant="subtitle1">Pièces</Typography>
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={handleAddPart}
                                size="small"
                            >
                                Ajouter une Pièce
                            </Button>
                        </Stack>

                        {partsList.map((part, index) => (
                            <Stack key={index} direction="row" spacing={2} sx={{ mb: 2 }}>
                                <TextField
                                    fullWidth
                                    label="Nom de la pièce"
                                    value={part.item}
                                    onChange={(e) => handlePartChange(index, 'item', e.target.value)}
                                />
                                <TextField
                                    type="number"
                                    label="Quantité"
                                    InputProps={{ inputProps: { min: 1 } }}
                                    value={part.units}
                                    onChange={(e) => handlePartChange(index, 'units', parseInt(e.target.value) || 1)}
                                    sx={{ width: 120 }}
                                />
                                {partsList.length > 1 && (
                                    <IconButton
                                        color="error"
                                        onClick={() => handleRemovePart(index)}
                                        sx={{ mt: 1 }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                            </Stack>
                        ))}
                    </Box>

                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={loading || !selectedPickup || partsList.some(part => !part.item.trim())}
                        size="large"
                    >
                        Envoyer la Commande
                    </Button>
                </Box>
            </Paper>
            <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
                <Typography variant="h5" component="h3" gutterBottom>
                    Liste des Commandes
                </Typography>
                
                {loading ? (
                    <Box display="flex" justifyContent="center" p={3}>
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Magasin</TableCell>
                                        <TableCell>Demandeur</TableCell>
                                        <TableCell>PO</TableCell>
                                        <TableCell>Items</TableCell>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Status</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pickupsList.map((pickup) => (
                                        <TableRow key={pickup.id}>
                                            <TableCell>{pickup.name}</TableCell>
                                            <TableCell>{pickup.created_by}</TableCell>
                                            <TableCell>{stores[pickup.store-1]}</TableCell>
                                            <TableCell>
                                                {JSON.parse(pickup.products).map((item, index) => (
                                                    <div key={index}>
                                                        {item.item} ({item.units})
                                                    </div>
                                                ))}
                                            </TableCell>
                                            <TableCell>
                                                {new Date(pickup.created_at).toLocaleString()}
                                            </TableCell>
                                            <TableCell>
                                                {pickup.is_archived ? 'Complété' : 'En attente'}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box display="flex" justifyContent="center" mt={3}>
                            <Pagination 
                                count={totalPages}
                                page={page}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    </>
                )}
            </Paper>
        </Container>
    );
};

export default Transfers;