import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
  Chip,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const LocationDetailsModal = ({
  open,
  onClose,
  title,
  loading,
  data,
  username,
  timePeriod,
  type,
  expectedCount,
}) => {
  const formatTimePeriod = (period) => {
    switch (period) {
      case 'all':
        return 'All Time';
      case 'last_30_days':
        return 'Last 30 Days';
      case '31_to_60_days':
        return '31 to 60 Days';
      default:
        return period;
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  // Format location to be more compact
  const formatLocation = (location) => {
    if (!location) return '';

    // If it's a simple location without commas, return as is
    if (!location.includes(',')) {
      return location;
    }

    // Split multiple locations and display them in a more compact way
    const locations = location.split(',');

    // If there are only a few locations, display them with line breaks
    if (locations.length <= 3) {
      return (
        <span style={{ whiteSpace: 'pre-line' }}>{locations.join('\n')}</span>
      );
    }

    // For many locations, show first two with a count
    return (
      <Tooltip title={locations.join('\n')} arrow placement='top'>
        <span>
          {locations[0]}
          <br />
          {locations[1]}
          <br />
          <Chip
            label={`+${locations.length - 2} more`}
            size='small'
            variant='outlined'
            color='primary'
            sx={{ fontSize: '0.7rem', height: '20px' }}
          />
        </span>
      </Tooltip>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='lg'
      fullWidth
      aria-labelledby='details-dialog-title'>
      <DialogTitle
        id='details-dialog-title'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography
            variant='h6'
            component='div'
            sx={{ fontWeight: 'bold', color: 'var(--h1)' }}>
            {title} - {username} ({formatTimePeriod(timePeriod)})
          </Typography>
          <Chip
            label={`${expectedCount} records`}
            color='primary'
            size='small'
            sx={{
              visibility: loading ? 'hidden' : 'visible',
              bgcolor: data.length > 0 ? 'primary.main' : 'grey.500',
            }}
          />
        </Box>
        <IconButton aria-label='close' onClick={onClose} size='large'>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : data.length === 0 ? (
          <Typography variant='body1' align='center' sx={{ p: 3 }}>
            No data available for this time period.
          </Typography>
        ) : type === 'location' ? (
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    UPC
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Name
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Location
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Action
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Timestamp
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.upc}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell style={{ maxWidth: '200px' }}>
                      {formatLocation(item.full_location)}
                    </TableCell>
                    <TableCell>{item.action_type}</TableCell>
                    <TableCell>{formatDate(item.timestamp)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer component={Paper} elevation={0}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Item
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Description
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    UPC
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: 'bold',
                      backgroundColor: '#f5f5f5',
                      maxWidth: '200px',
                    }}>
                    Location
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Order #
                  </TableCell>
                  <TableCell
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Picked At
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.item}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.upc}</TableCell>
                    <TableCell style={{ maxWidth: '200px' }}>
                      {formatLocation(item.location)}
                    </TableCell>
                    <TableCell>{item.order_number}</TableCell>
                    <TableCell>{formatDate(item.picked_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LocationDetailsModal;
