import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getArchivedLocalisationsBatch } from '../controller/LocController';
import {
  Typography,
  CircularProgress,
  Pagination,
  Box,
  Container,
  TextField,
  Button,
  Stack,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ITEMS_PER_PAGE = 100;

const ArchiveLocPage = () => {
  const navigate = useNavigate();
  const [archivedLocations, setArchivedLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [total, setTotal] = useState(0);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const fetchArchivedData = async (page, searchTerm = '') => {
    setLoading(true);
    try {
      const { localisations, total } = await getArchivedLocalisationsBatch(
        page,
        ITEMS_PER_PAGE,
        searchTerm
      );
      setArchivedLocations(localisations);
      setTotal(total);
    } catch (error) {
      console.error('Error fetching archived localisations:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Use the current search term when changing pages
    fetchArchivedData(page, searchTerm);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearch = async () => {
    setPage(1);
    await fetchArchivedData(1, searchTerm);
  };

  const resetFilter = () => {
    setSearchTerm('');
    setPage(1);
    fetchArchivedData(1, '');
  };

  const handleBackToLocations = () => {
    navigate(-1); // Go back to previous page
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })}`;
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth={false} sx={{ marginTop: '20px' }}>
      <Button
        variant='outlined'
        startIcon={<ArrowBackIcon />}
        onClick={handleBackToLocations}
        sx={{ mb: 2 }}>
        Retour aux localisations
      </Button>

      <Typography variant='h4' sx={{ mb: 3, color: '#555' }}>
        Localisations Archivées
      </Typography>

      <Stack
        direction='row'
        spacing={2}
        sx={{ margin: '20px auto', width: '80%' }}>
        <TextField
          label='Recherche par UPC ou NOM'
          variant='outlined'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSearch();
          }}
          fullWidth
        />
        <Button variant='contained' color='primary' onClick={handleSearch}>
          Search
        </Button>
        <Button variant='contained' color='secondary' onClick={resetFilter}>
          Reset
        </Button>
      </Stack>

      {searchTerm && (
        <Typography variant='subtitle1' sx={{ mt: 2, mb: 2 }}>
          Résultats pour: <strong>{searchTerm}</strong> - Page {page} sur{' '}
          {Math.ceil(total / ITEMS_PER_PAGE)}
        </Typography>
      )}

      {archivedLocations.length === 0 ? (
        <Box sx={{ textAlign: 'center', mt: 4, mb: 4 }}>
          <Typography variant='h6' color='text.secondary'>
            Aucune localisation archivée trouvée
          </Typography>
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 3, mb: 3 }}>
          <Table sx={{ minWidth: 650 }} aria-label='archived locations table'>
            <TableHead sx={{ backgroundColor: '#f0f0f0' }}>
              <TableRow>
                <TableCell>
                  <strong>Nom</strong>
                </TableCell>
                <TableCell>
                  <strong>UPC</strong>
                </TableCell>
                <TableCell>
                  <strong>Emplacement</strong>
                </TableCell>
                <TableCell>
                  <strong>Détails</strong>
                </TableCell>
                <TableCell>
                  <strong>Bin</strong>
                </TableCell>
                <TableCell>
                  <strong>Archivé par</strong>
                </TableCell>
                <TableCell>
                  <strong>Date d'archivage</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {archivedLocations.map((loc, index) => (
                <TableRow
                  key={`${loc.upc}-${index}`}
                  sx={{ '&:nth-of-type(odd)': { backgroundColor: '#fafafa' } }}>
                  <TableCell>{loc.name}</TableCell>
                  <TableCell>{loc.upc}</TableCell>
                  <TableCell>{loc.full_location}</TableCell>
                  <TableCell>
                    Level: {loc.level}, Row: {loc.row}, Side: {loc.side},
                    Column: {loc.column}, Shelf: {loc.shelf}
                  </TableCell>
                  <TableCell>{loc.bin || 'No bin'}</TableCell>
                  <TableCell>{loc.updated_by}</TableCell>
                  <TableCell>{formatDate(loc.updated_at)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '20px',
          paddingBottom: '50px',
        }}>
        <Pagination
          count={Math.ceil(total / ITEMS_PER_PAGE)}
          page={page}
          onChange={handlePageChange}
          color='primary'
          size='large'
        />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert
          onClose={handleSnackbarClose}
          severity='success'
          sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ArchiveLocPage;
