// Navbar.jsx
import React, { useState, useEffect } from 'react';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  useMediaQuery,
} from '@mui/material';
// Import icons individually instead of using * as Icons
import MapIcon from '@mui/icons-material/Map';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import PreviewIcon from '@mui/icons-material/Preview';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import RuleIcon from '@mui/icons-material/Rule';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { Link, useNavigate } from 'react-router-dom';
import '../../App.css';

// styles.js
export const navbarStyles = {
  drawer: (open, drawerWidth) => ({
    width: open ? drawerWidth : '60px',
    flexShrink: 0,
    transition: 'width 0.3s',
    '& .MuiDrawer-paper': {
      width: open ? drawerWidth : '60px',
      boxSizing: 'border-box',
      backgroundColor: 'var(--dark-blue)',
      transition: 'width 0.3s',
      overflow: 'hidden',
      height: '100vh',
      justifyContent: 'space-between',
      overflowY: 'hidden',
    },
  }),

  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: 2,
    cursor: 'pointer',
    borderBottom: '1px solid var(--light-blue)',
    marginBottom: '20px',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },

  mobileMenuContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 1,
  },

  menuButton: {
    color: 'var(--light-blue)',
  },

  scrollContainer: {
    overflowY: 'auto',
    height: 'calc(100vh - 60px)',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
  },

  listItemIcon: (open) => ({
    minWidth: '40px',
    transition: 'padding 0.3s',
    paddingRight: open ? '16px' : '8px',
    color: 'var(--light-blue)',
  }),

  listItemText: (open) => ({
    opacity: open ? 1 : 0,
    transition: 'opacity 0.3s, margin-left 0.3s',
    marginLeft: open ? '0px' : '-20px',
  }),

  textTypography: {
    fontWeight: 500,
    fontSize: '20px',
    color: 'var(--light-blue)',
  },

  logoutIcon: (open) => ({
    transition: 'padding 0.3s',
    paddingRight: open ? '16px' : '8px',
    color: 'var(--red)',
    cursor: 'pointer',
    paddingBottom: '20px',
    justifyContent: open ? 'center' : 'left',
    textAlign: open ? 'center' : 'left',
    width: '100%',
  }),

  mainContent: (open, drawerWidth) => ({
    flexGrow: 1,
    p: 3,
    marginLeft: open ? `${drawerWidth}px` : '60px',
    transition: 'margin-left 0.3s',
    margin: 0,
    padding: 0,
  }),
};

const drawerWidth = 300;

const Navbar = ({ userPermissions = [] }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const isLargeScreen = useMediaQuery('(min-width:1440px)');
  const isMobileOrTablet = useMediaQuery('(max-width:1440px)');
  const isLoggedIn = !!localStorage.getItem('access_token');
  const canCreateUsers = userPermissions.includes('create_users');
  const hasDispatchPerm = userPermissions.includes('dispatch');

  useEffect(() => {
    setOpen(isLargeScreen);
  }, [isLargeScreen]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
  };

  // Map icon names to their actual components
  const iconComponents = {
    Map: MapIcon,
    EditLocation: EditLocationIcon,
    LocalShipping: LocalShippingIcon,
    HowToReg: HowToRegIcon,
    ManageAccounts: ManageAccountsIcon,
    PersonSearch: PersonSearchIcon,
    TransferWithinAStation: TransferWithinAStationIcon,
    Preview: PreviewIcon,
    DriveEta: DriveEtaIcon,
    TaxiAlert: TaxiAlertIcon,
    QueryStats: QueryStatsIcon,
    Assessment: AssessmentIcon,
    FactCheck: FactCheckIcon,
    PrecisionManufacturing: PrecisionManufacturingIcon,
    Rule: RuleIcon,
    LocalActivityIcon: LocalActivityIcon,
  };

  const renderNavItem = (to, icon, text) => {
    const IconComponent = iconComponents[icon];
    return (
      <ListItem button component={Link} to={to}>
        <ListItemIcon sx={navbarStyles.listItemIcon(open)}>
          {IconComponent ? <IconComponent /> : null}
        </ListItemIcon>
        <ListItemText
          primary={text}
          primaryTypographyProps={{ sx: navbarStyles.textTypography }}
          sx={navbarStyles.listItemText(open)}
        />
      </ListItem>
    );
  };

  if (!isLoggedIn) return null;

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant='permanent'
        open={open}
        sx={navbarStyles.drawer(open, drawerWidth)}>
        {isLargeScreen && (
          <Box sx={navbarStyles.logoContainer} onClick={() => navigate('/')}>
            <img
              src='/logoSuper.png'
              alt='Logo'
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>
        )}

        {isMobileOrTablet && (
          <Box sx={navbarStyles.mobileMenuContainer}>
            <IconButton
              onClick={handleDrawerToggle}
              sx={navbarStyles.menuButton}>
              <MenuIcon />
            </IconButton>
          </Box>
        )}

        <Box sx={navbarStyles.scrollContainer}>
          <List>
            {renderNavItem('/', 'Map', 'Map')}
            {canCreateUsers && (
              <>
                {renderNavItem('/coords', 'EditLocation', 'Coordonnées')}
                {renderNavItem(
                  '/delivery_stats',
                  'LocalShipping',
                  'Stats Livraisons'
                )}
                {renderNavItem('/signup', 'HowToReg', 'Register')}
                {renderNavItem('/update', 'ManageAccounts', 'Update Usager')}
                {renderNavItem(
                  '/picker_stats',
                  'PersonSearch',
                  'Picker/Locator Stats'
                )}
                {renderNavItem('/pickup', 'TransferWithinAStation', 'Pickup')}
              </>
            )}

            {hasDispatchPerm && (
              <>
                {renderNavItem(
                  '/tickets',
                  'LocalActivityIcon',
                  'Opened Tickets'
                )}{' '}
                {renderNavItem('/picker_form', 'Preview', 'Démo Client')}
                {renderNavItem('/drivers', 'DriveEta', 'Drivers')}
                {renderNavItem('/driverStats', 'TaxiAlert', 'Driver Stats')}
                {renderNavItem('/stats', 'QueryStats', 'Stats Client')}
                {/* Changed from LocatlActivity since it's not defined */}
                {renderNavItem('/rapport', 'Assessment', 'Rapport')}
                {renderNavItem('/psl', 'FactCheck', 'PSL')}
                {renderNavItem(
                  '/loc',
                  'PrecisionManufacturing',
                  'Localisation'
                )}
                {renderNavItem('/missing', 'Rule', 'Manquantes')}
              </>
            )}
          </List>

          {isLoggedIn && (
            <ListItem button onClick={handleLogout}>
              <ListItemIcon sx={navbarStyles.logoutIcon(open)}>
                <LogoutIcon sx={{ fontSize: open ? '2.5rem' : '2rem' }} />
              </ListItemIcon>
            </ListItem>
          )}
        </Box>
      </Drawer>

      <Box component='main' sx={navbarStyles.mainContent(open, drawerWidth)} />
    </Box>
  );
};

export default Navbar;
