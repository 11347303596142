export const CarIcon = (color = '#ff2800', size = 32) => {
  return {
    html: `
      <svg 
        width="${size}" 
        height="${size + 25}" 
        viewBox="0 0 24 30" 
        fill="${color}" 
        class="car-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(0, 0)">
          <path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z"/>
          <circle cx="6.5" cy="14.5" r="1.5" fill="#333"/>
          <circle cx="17.5" cy="14.5" r="1.5" fill="#333"/>
        </g>
      </svg>
    `,
    className: 'car-marker',
    iconSize: [size, size + 25], // Add extra height for tooltip
    iconAnchor: [size / 2, size / 2], // Keep the anchor at the car center
  };
};

export const ClientIcon = (color = '#3388ff', size = 24) => {
  return {
    html: `
      <svg 
        width="${size}" 
        height="${size}" 
        viewBox="0 0 24 24" 
        fill="${color}" 
        class="client-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
        <circle cx="12" cy="9" r="2.5" fill="#fff"/>
      </svg>
    `,
    className: 'client-marker',
    iconSize: [size, size],
    iconAnchor: [size / 2, size],
  };
};

export const WarehouseIcon = (color = '#f44336', size = 28) => {
  return {
    html: `
      <svg 
        width="${size}" 
        height="${size}" 
        viewBox="0 0 24 24" 
        fill="${color}" 
        class="warehouse-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 8h-3V4H3c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm12 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM4 14h14l2-3h-6V5H4v9z"/>
        <circle cx="6" cy="17" r="1.5" fill="#fff"/>
        <circle cx="18" cy="17" r="1.5" fill="#fff"/>
      </svg>
    `,
    className: 'warehouse-marker',
    iconSize: [size, size],
    iconAnchor: [size / 2, size],
  };
};

export const HouseIcon = (color = '#4CAF50', size = 28) => {
  return {
    html: `
      <svg 
        width="${size}" 
        height="${size}" 
        viewBox="0 0 24 24" 
        fill="${color}" 
        class="house-icon"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19 9.3V4h-3v2.6L12 3 2 12h3v8h5v-6h4v6h5v-8h3l-3-2.7zM10 10c0-1.1.9-2 2-2s2 .9 2 2h-4z"/>
        <circle cx="12" cy="9" r="1.75" fill="#fff"/>
      </svg>
    `,
    className: 'house-marker',
    iconSize: [size, size],
    iconAnchor: [size / 2, size],
  };
};

// You can keep the default export if needed
const MapIcons = {
  CarIcon,
  ClientIcon,
  WarehouseIcon,
  HouseIcon,
};

export default MapIcons;
