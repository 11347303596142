// ticketController.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const X_DISPATCH_KEY =
  'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj';

const headers = {
  'Content-Type': 'application/json',
  'X-Dispatch-key': X_DISPATCH_KEY,
};

/**
 * Create a new ticket in the system
 * @param {Object} ticketData - Contains ticket information
 * @param {string} ticketData.title - Ticket title
 * @param {string} ticketData.client_name - Name of the client
 * @param {string} [ticketData.client_number] - Client's phone number (optional)
 * @param {Array} [ticketData.items] - List of items in the ticket (optional)
 * @param {string} ticketData.commis_name - Name of the person creating the ticket
 * @returns {Promise<Object>} - New ticket data
 */
export const createTicket = async (ticketData) => {
  try {
    // Create the request payload including the store ID
    const requestBody = {
      ...ticketData,
    };

    console.log('Create ticket payload:', requestBody);

    // Based on your backend router, try the correct endpoint
    const response = await axios.post(
      `${API_URL}/misc_dispatch/create_ticket`,
      requestBody,
      { headers }
    );

    console.log('Create ticket response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error creating ticket:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
    throw error;
  }
};

/**
 * Get all tickets with optional filtering
 * @param {Object} options - Filter options
 * @param {number} [options.skip=0] - Number of records to skip (pagination)
 * @param {number} [options.limit=100] - Maximum number of records to return
 * @param {string} [options.search] - Search string for filtering tickets
 * @param {boolean} [options.in_work] - Filter by in_work status
 * @param {boolean} [options.closed] - Filter by closed status
 * @returns {Promise<Array>} - List of tickets matching criteria
 */
export const getAllTickets = async (options = {}) => {
  try {
    // Set default pagination values
    const skip = options.skip || 0;
    const limit = options.limit || 100;

    // Build query params - but also be prepared to use POST if GET doesn't work
    let url = `${API_URL}/misc_dispatch/get_all_tickets?skip=${skip}&limit=${limit}`;

    // Add optional filters if provided
    if (options.search !== undefined)
      url += `&search=${encodeURIComponent(options.search)}`;
    if (options.in_work !== undefined) url += `&in_work=${options.in_work}`;
    if (options.closed !== undefined) url += `&closed=${options.closed}`;

    console.log('Get tickets URL:', url);

    try {
      // First try with GET as specified in your router
      const response = await axios.get(url, { headers });
      console.log('Get tickets response:', response.data);
      return response.data.tickets || [];
    } catch (getError) {
      console.log('GET request failed, trying POST:', getError);

      // If GET fails, try POST as fallback (some implementations use POST for everything)
      const requestBody = {
        skip: skip,
        limit: limit,
      };

      if (options.search !== undefined) requestBody.search = options.search;
      if (options.in_work !== undefined) requestBody.in_work = options.in_work;
      if (options.closed !== undefined) requestBody.closed = options.closed;

      const response = await axios.post(
        `${API_URL}/misc_dispatch/get_all_tickets`,
        requestBody,
        { headers }
      );

      console.log('Get tickets POST response:', response.data);
      return response.data.tickets || [];
    }
  } catch (error) {
    console.error('Error getting tickets:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
    throw error;
  }
};

/**
 * Update a ticket's title
 * @param {number} ticketId - ID of the ticket to update
 * @param {string} newTitle - New title for the ticket
 * @returns {Promise<Object>} - Updated ticket data
 */
export const updateTicketTitle = async (ticketId, newTitle) => {
  try {
    // Get the access token from localStorage
    const token = localStorage.getItem('access_token');

    if (!token) {
      console.error('No access token found');
      throw new Error('You must be logged in to update ticket titles');
    }

    console.log('Token found, attempting to update ticket title');

    // Create headers with authentication
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };

    // Try with the expected format from your router
    const requestBody = {
      title: newTitle,
    };

    console.log('Update ticket title payload:', requestBody);

    try {
      // First try with PATCH as specified in your router
      const response = await axios.patch(
        `${API_URL}/misc_dispatch/update_tickets_by_id/${ticketId}/title`,
        requestBody,
        { headers: authHeaders }
      );

      console.log('Update ticket title response:', response.data);
      return response.data;
    } catch (patchError) {
      console.log('PATCH request failed, trying POST:', patchError);

      // If PATCH fails, try POST with the store ID included
      const postRequestBody = {
        ticket_id: ticketId,
        title: newTitle,
      };

      const response = await axios.post(
        `${API_URL}/misc_dispatch/update_ticket_title`,
        postRequestBody,
        { headers: authHeaders }
      );

      console.log('Update ticket title POST response:', response.data);
      return response.data;
    }
  } catch (error) {
    console.error('Error updating ticket title:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
    throw error;
  }
};

/**
 * Add a comment to a ticket
 * @param {number} ticketId - ID of the ticket
 * @param {string} comment - Comment text to add
 * @returns {Promise<Object>} - Updated ticket data
 */
export const addTicketComment = async (ticketId, comment) => {
  try {
    // Try with the expected format from your router
    const requestBody = {
      comment: comment,
    };

    console.log('Add ticket comment payload:', requestBody);

    try {
      // First try with PATCH as specified in your router
      const response = await axios.patch(
        `${API_URL}/misc_dispatch/tickets_comment/${ticketId}/comment`,
        requestBody,
        { headers }
      );

      console.log('Add ticket comment response:', response.data);
      return response.data;
    } catch (patchError) {
      console.log('PATCH request failed, trying POST:', patchError);

      // If PATCH fails, try POST with the store ID included
      const postRequestBody = {
        ticket_id: ticketId,
        comment: comment,
      };

      const response = await axios.post(
        `${API_URL}/misc_dispatch/add_ticket_comment`,
        postRequestBody,
        { headers }
      );

      console.log('Add ticket comment POST response:', response.data);
      return response.data;
    }
  } catch (error) {
    console.error('Error adding ticket comment:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
    throw error;
  }
};

/**
 * Set the in_work status of a ticket
 * @param {number} ticketId - ID of the ticket
 * @param {boolean} inWork - Whether the ticket is in work
 * @returns {Promise<Object>} - Updated ticket data
 */
export const setTicketInWork = async (ticketId, inWork) => {
  try {
    // Try with the expected format from your router
    const requestBody = {
      in_work: inWork,
    };

    console.log('Set ticket in_work payload:', requestBody);

    try {
      // First try with PATCH as specified in your router
      const response = await axios.patch(
        `${API_URL}/misc_dispatch/set_tickets_inwork/${ticketId}/in-work`,
        requestBody,
        { headers }
      );

      console.log('Set ticket in_work response:', response.data);
      return response.data;
    } catch (patchError) {
      console.log('PATCH request failed, trying POST:', patchError);

      // If PATCH fails, try POST with the store ID included
      const postRequestBody = {
        ticket_id: ticketId,
        in_work: inWork,
      };

      const response = await axios.post(
        `${API_URL}/misc_dispatch/set_ticket_in_work`,
        postRequestBody,
        { headers }
      );

      console.log('Set ticket in_work POST response:', response.data);
      return response.data;
    }
  } catch (error) {
    console.error('Error setting ticket in_work status:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
    throw error;
  }
};

/**
 * Close a ticket
 * @param {number} ticketId - ID of the ticket to close
 * @param {string} closedBy - Name of the person closing the ticket
 * @returns {Promise<Object>} - Updated ticket data
 */
// Close a ticket
// Close a ticket
// Close a ticket
export const closeTicket = async (ticketId) => {
  try {
    // Get the access token from localStorage
    const token = localStorage.getItem('access_token');

    if (!token) {
      console.error('No access token found');
      throw new Error('You must be logged in to close tickets');
    }

    console.log('Token found, attempting to close ticket');

    // Create headers with authentication
    const authHeaders = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };

    console.log(
      'Sending request to close ticket:',
      ticketId,
      'with headers:',
      authHeaders
    );

    const response = await axios.patch(
      `${API_URL}/misc_dispatch/close_tickets/${ticketId}/close`,
      {}, // Empty body since we get user info from token
      { headers: authHeaders }
    );

    console.log('Close ticket response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error closing ticket:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
    throw error;
  }
};

/**
 * Get a single ticket by ID
 * @param {number} ticketId - ID of the ticket to retrieve
 * @returns {Promise<Object>} - Ticket data
 */
export const getTicketById = async (ticketId) => {
  try {
    console.log('Getting ticket with ID:', ticketId);

    try {
      // First try with GET as specified in your router
      const response = await axios.get(
        `${API_URL}/misc_dispatch/get_tickets_id/${ticketId}`,
        { headers }
      );

      console.log('Get ticket response:', response.data);
      return response.data;
    } catch (getError) {
      console.log('GET request failed, trying POST:', getError);

      // If GET fails, try POST as fallback
      const requestBody = {
        ticket_id: ticketId,
      };

      const response = await axios.post(
        `${API_URL}/misc_dispatch/get_ticket_by_id`,
        requestBody,
        { headers }
      );

      console.log('Get ticket POST response:', response.data);
      return response.data;
    }
  } catch (error) {
    console.error('Error getting ticket:', {
      status: error.response?.status,
      data: error.response?.data,
      message: error.message,
    });
    throw error;
  }
};
