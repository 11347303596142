import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Grid,
  Container,
  IconButton,
  Divider,
  Snackbar,
  Alert,
  CircularProgress,
  Card,
  CardContent,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  Fab,
  Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  createTicket,
  getAllTickets,
  setTicketInWork,
  closeTicket,
  addTicketComment,
  updateTicketTitle,
} from '../controller/ticketSystemController';

const TicketView = () => {
  // Form state
  const [ticketData, setTicketData] = useState({
    title: '',
    client_name: '',
    client_number: '',
    commis_name: '',
    comments: '',
    items: [
      // Start with one empty item
      {
        name: '',
        quantity: 1,
        description: '',
      },
    ],
  });

  // UI state
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [filteredTickets, setFilteredTickets] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [alertInfo, setAlertInfo] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const [autoRefreshEnabled, setAutoRefreshEnabled] = useState(true);
  const [lastRefreshTime, setLastRefreshTime] = useState(new Date());
  const [elapsedTimes, setElapsedTimes] = useState({});
  const [updateTitleDialogOpen, setUpdateTitleDialogOpen] = useState(false);
  const [selectedTicketForUpdate, setSelectedTicketForUpdate] = useState(null);
  const [newTitle, setNewTitle] = useState('');

  // Reference to the refresh timer
  const refreshTimerRef = useRef(null);

  const handleOpenUpdateTitleDialog = (ticket) => {
    setSelectedTicketForUpdate(ticket);
    setNewTitle(ticket.title); // Pre-fill with current title
    setUpdateTitleDialogOpen(true);
  };

  const handleUpdateTitle = async () => {
    if (!newTitle.trim()) {
      showAlert('Title cannot be empty', 'warning');
      return;
    }

    try {
      // Check if user has permission first (provides better UX)
      if (!userPermissions.includes('create_users')) {
        showAlert(
          'You do not have permission to update ticket titles',
          'error'
        );
        return;
      }

      setLoading(true);
      await updateTicketTitle(selectedTicketForUpdate.id, newTitle);
      setUpdateTitleDialogOpen(false);
      showAlert('Ticket title updated successfully', 'success');
      fetchTickets(); // Refresh ticket list
    } catch (error) {
      if (error.response?.status === 403) {
        showAlert(
          'You do not have permission to update ticket titles',
          'error'
        );
      } else if (error.response?.status === 401) {
        showAlert('Authentication required - please log in again', 'error');
      } else {
        showAlert(
          'Failed to update ticket title: ' +
            (error.response?.data?.detail || error.message),
          'error'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  // Load existing tickets and user permissions on component mount
  useEffect(() => {
    fetchTickets();

    try {
      // First try to get permissions from user_permissions directly
      const permissionsString = localStorage.getItem('user_permissions');
      if (permissionsString) {
        const permissions = JSON.parse(permissionsString);
        setUserPermissions(permissions);
        console.log('Loaded permissions from user_permissions:', permissions);
      }
      // If that fails, try to get it from the userJsonInfo object
      else {
        const userInfoString = localStorage.getItem('userJsonInfo');
        if (userInfoString) {
          const userInfo = JSON.parse(userInfoString);
          if (userInfo.role && userInfo.role.permissions) {
            const permNames = userInfo.role.permissions.map((p) => p.name);
            setUserPermissions(permNames);
            console.log('Loaded permissions from userJsonInfo:', permNames);
          }
        } else {
          console.warn('No permissions found in localStorage');
        }
      }
    } catch (error) {
      console.error('Error loading user permissions:', error);
    }
  }, []);

  // Set up auto-refresh timer
  useEffect(() => {
    // Create function to handle the refresh
    const refreshData = () => {
      // Only refresh if auto-refresh is enabled and no dialogs are open
      if (autoRefreshEnabled && !createDialogOpen && !commentDialogOpen) {
        console.log('Auto-refreshing ticket data...');
        fetchTickets();
        setLastRefreshTime(new Date());
      } else {
        console.log('Auto-refresh skipped - dialog open or disabled');
      }
    };

    // Set up interval for auto-refresh (every 60 seconds)
    refreshTimerRef.current = setInterval(refreshData, 60000);

    // Clean up function
    return () => {
      if (refreshTimerRef.current) {
        clearInterval(refreshTimerRef.current);
      }
    };
  }, [autoRefreshEnabled, createDialogOpen, commentDialogOpen]);

  // Filter tickets based on search query
  useEffect(() => {
    if (!searchQuery) {
      setFilteredTickets(tickets);
      return;
    }

    const lowercaseQuery = searchQuery.toLowerCase();
    const results = tickets.filter(
      (ticket) =>
        ticket.title.toLowerCase().includes(lowercaseQuery) ||
        ticket.client_name.toLowerCase().includes(lowercaseQuery) ||
        ticket.commis_name.toLowerCase().includes(lowercaseQuery)
    );
    setFilteredTickets(results);
  }, [searchQuery, tickets]);

  const formatTimeDifference = (createdAt) => {
    const now = new Date();
    const created = new Date(createdAt);
    const diffInSeconds = Math.floor((now - created) / 1000);

    const days = Math.floor(diffInSeconds / (60 * 60 * 24));
    const hours = Math.floor((diffInSeconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((diffInSeconds % (60 * 60)) / 60);
    const seconds = diffInSeconds % 60;

    let timeString = '';

    if (days > 0) {
      timeString += `${days}d `;
    }

    if (hours > 0 || days > 0) {
      timeString += `${hours}h `;
    }

    timeString += `${minutes}m ${seconds}s`;

    return timeString;
  };

  // Add this useEffect to update the timers every second
  // Update the useEffect that manages the timer updates
  useEffect(() => {
    // Initialize elapsed times for all tickets
    if (tickets.length > 0 && Object.keys(elapsedTimes).length === 0) {
      const initialElapsedTimes = {};
      tickets.forEach((ticket) => {
        // Calculate time for all tickets initially, but we'll stop updating closed ones
        initialElapsedTimes[ticket.id] = formatTimeDifference(
          ticket.created_at
        );
      });
      setElapsedTimes(initialElapsedTimes);
    }

    // Set up interval to update elapsed times every second
    const intervalId = setInterval(() => {
      if (tickets.length > 0) {
        const updatedElapsedTimes = { ...elapsedTimes }; // Start with existing times

        tickets.forEach((ticket) => {
          // Only update times for tickets that are not closed
          if (!ticket.ticket_closed) {
            updatedElapsedTimes[ticket.id] = formatTimeDifference(
              ticket.created_at
            );
          }
          // Closed tickets keep their last calculated time
        });

        setElapsedTimes(updatedElapsedTimes);
      }
    }, 1000);

    // Clean up interval when component unmounts
    return () => clearInterval(intervalId);
  }, [tickets, elapsedTimes]); // Note: added elapsedTimes as a dependency

  // Function to fetch tickets
  // Function to fetch tickets
  const fetchTickets = async () => {
    try {
      setLoading(true);
      const response = await getAllTickets();
      setTickets(response || []);
      setFilteredTickets(response || []);

      // Calculate new elapsed times, but preserve existing times for closed tickets
      const newElapsedTimes = { ...elapsedTimes };

      response.forEach((ticket) => {
        // If it's a new ticket or an open ticket, update its time
        if (!newElapsedTimes[ticket.id] || !ticket.ticket_closed) {
          newElapsedTimes[ticket.id] = formatTimeDifference(ticket.created_at);
        }
        // Closed tickets that already have a time preserve their existing time
      });

      setElapsedTimes(newElapsedTimes);
      setLastRefreshTime(new Date());
    } catch (error) {
      showAlert('Failed to load tickets', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Handle manual refresh
  const handleManualRefresh = () => {
    // Clear any existing timer
    if (refreshTimerRef.current) {
      clearInterval(refreshTimerRef.current);
    }

    // Fetch data immediately
    fetchTickets();

    // Reset the timer
    refreshTimerRef.current = setInterval(() => {
      if (autoRefreshEnabled && !createDialogOpen && !commentDialogOpen) {
        fetchTickets();
      }
    }, 60000);
  };

  // Handle input changes for the main form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTicketData({
      ...ticketData,
      [name]: value,
    });
  };

  // Handle item field changes
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...ticketData.items];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: field === 'quantity' ? Number(value) : value,
    };

    setTicketData({
      ...ticketData,
      items: updatedItems,
    });
  };

  // Add another item row
  const addItemRow = () => {
    setTicketData({
      ...ticketData,
      items: [...ticketData.items, { name: '', quantity: 1, description: '' }],
    });
  };

  // Remove an item
  const removeItemRow = (index) => {
    if (ticketData.items.length <= 1) {
      return; // Keep at least one item
    }
    const updatedItems = [...ticketData.items];
    updatedItems.splice(index, 1);
    setTicketData({
      ...ticketData,
      items: updatedItems,
    });
  };

  // Handle input changes for the search bar
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Clear search query
  const handleClearSearch = () => {
    setSearchQuery('');
  };

  // Open the create ticket dialog
  const handleOpenCreateDialog = () => {
    // Reset form when opening dialog
    setTicketData({
      title: '',
      client_name: '',
      client_number: '',
      commis_name: '',
      comments: '',
      items: [{ name: '', quantity: 1, description: '' }],
    });
    setCreateDialogOpen(true);
  };

  // Close the create ticket dialog
  const handleCloseCreateDialog = () => {
    setCreateDialogOpen(false);
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    // Form validation
    if (
      !ticketData.title ||
      !ticketData.client_name ||
      !ticketData.commis_name
    ) {
      showAlert('Please fill in all required fields', 'error');
      return;
    }

    // Validate that all items have names
    const emptyItems = ticketData.items.some((item) => !item.name.trim());
    if (emptyItems) {
      showAlert('All items must have names', 'error');
      return;
    }

    // Debug log
    console.log('Submitting ticket:', JSON.stringify(ticketData, null, 2));

    try {
      setLoading(true);
      await createTicket(ticketData);

      // Close the create dialog
      setCreateDialogOpen(false);

      showAlert('Ticket created successfully', 'success');
      fetchTickets(); // Refresh ticket list
    } catch (error) {
      showAlert(
        'Failed to create ticket: ' +
          (error.response?.data?.detail || error.message),
        'error'
      );
    } finally {
      setLoading(false);
    }
  };

  // Change ticket status (in_work)
  const handleToggleInWork = async (ticketId, currentStatus) => {
    try {
      setLoading(true);
      await setTicketInWork(ticketId, !currentStatus);
      showAlert(
        `Ticket ${!currentStatus ? 'in work' : 'not in work'}`,
        'success'
      );
      fetchTickets(); // Refresh ticket list
    } catch (error) {
      showAlert('Failed to update ticket status', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Close a ticket
  // Close a ticket
  const handleCloseTicket = async (ticketId) => {
    try {
      // Check if user has permission first (provides better UX)
      if (!userPermissions.includes('create_users')) {
        showAlert('You do not have permission to close tickets', 'error');
        return;
      }

      setLoading(true);
      await closeTicket(ticketId);

      // Update the elapsed times to ensure the timer stops immediately
      setElapsedTimes((prev) => ({
        ...prev,
        // Keep the current elapsed time for this ticket
        [ticketId]:
          prev[ticketId] ||
          formatTimeDifference(
            tickets.find((t) => t.id === ticketId)?.created_at
          ),
      }));

      showAlert('Ticket closed successfully', 'success');
      fetchTickets(); // Refresh ticket list
    } catch (error) {
      // Error handling...
    } finally {
      setLoading(false);
    }
  };

  // Open comment dialog
  const handleOpenCommentDialog = (ticket) => {
    setSelectedTicket(ticket);
    setCommentDialogOpen(true);
  };

  // Add comment to ticket
  const handleAddComment = async () => {
    if (!newComment.trim()) {
      showAlert('Comment cannot be empty', 'warning');
      return;
    }

    try {
      setLoading(true);
      await addTicketComment(selectedTicket.id, newComment);
      setCommentDialogOpen(false);
      setNewComment('');
      showAlert('Comment added successfully', 'success');
      fetchTickets(); // Refresh ticket list
    } catch (error) {
      showAlert('Failed to add comment', 'error');
    } finally {
      setLoading(false);
    }
  };

  // Display alert message
  const showAlert = (message, severity) => {
    setAlertInfo({
      open: true,
      message,
      severity,
    });
  };

  // Close alert
  const handleCloseAlert = () => {
    setAlertInfo({
      ...alertInfo,
      open: false,
    });
  };

  // Format the last refresh time
  const formatLastRefreshTime = () => {
    return lastRefreshTime.toLocaleTimeString();
  };

  // Adjust this to match the actual permission name in your system
  const hasClosePermission = userPermissions.some(
    (perm) => perm === 'create_users' || perm === 'create_user'
  );

  const isTicketOld = (createdAt) => {
    const now = new Date();
    const created = new Date(createdAt);
    const diffInMinutes = Math.floor((now - created) / (1000 * 60));
    return diffInMinutes >= 30;
  };

  return (
    <Container maxWidth='xl'>
      <Box sx={{ my: 4, position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,
          }}>
          <Typography
            variant='h4'
            component='h1'
            sx={{
              fontWeight: 'bold',
              color: 'purple',
            }}>
            Ticket Management System
          </Typography>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            onClick={handleOpenCreateDialog}>
            Create New Ticket
          </Button>
        </Box>

        {/* Search bar for tickets */}
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              fullWidth
              placeholder='Search tickets by title, client name, or created by...'
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searchQuery && (
                  <InputAdornment position='end'>
                    <IconButton size='small' onClick={handleClearSearch}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Switch
                    size='small'
                    checked={autoRefreshEnabled}
                    onChange={(e) => setAutoRefreshEnabled(e.target.checked)}
                  />
                }
                label={`Auto-refresh ${
                  autoRefreshEnabled ? 'enabled' : 'disabled'
                }`}
              />
              <Typography
                variant='caption'
                color='text.secondary'
                sx={{ ml: 2 }}>
                Last updated: {formatLastRefreshTime()}
              </Typography>
            </Box>

            <Button
              variant='outlined'
              color='primary'
              onClick={handleManualRefresh}
              startIcon={
                loading ? <CircularProgress size={16} /> : <RefreshIcon />
              }
              disabled={loading}>
              {loading ? 'Refreshing...' : 'Refresh Now'}
            </Button>
          </Box>
        </Paper>

        {/* Ticket List */}
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant='h5' gutterBottom>
            Tickets{' '}
            {searchQuery && `(Search Results: ${filteredTickets.length})`}
          </Typography>

          {loading && filteredTickets.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          ) : filteredTickets.length === 0 ? (
            <Typography variant='body1' align='center' sx={{ py: 3 }}>
              {searchQuery
                ? 'No tickets match your search criteria'
                : 'No tickets found'}
            </Typography>
          ) : (
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                {filteredTickets.map((ticket) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={ticket.id}>
                    <Card
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor:
                          isTicketOld(ticket.created_at) &&
                          !ticket.ticket_closed
                            ? 'rgba(255, 0, 0, 0.1)'
                            : 'inherit',
                        border:
                          isTicketOld(ticket.created_at) &&
                          !ticket.ticket_closed
                            ? '1px solid rgba(255, 0, 0, 0.3)'
                            : 'inherit',
                        transition: 'background-color 0.3s ease',
                      }}>
                      <CardContent
                        sx={{
                          flexGrow: 1,
                          position: 'relative',
                          pt:
                            isTicketOld(ticket.created_at) &&
                            !ticket.ticket_closed
                              ? 4
                              : 2,
                        }}>
                        {isTicketOld(ticket.created_at) &&
                          !ticket.ticket_closed && (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                backgroundColor: 'error.main',
                                color: 'white',
                                py: 0.5,
                                px: 1,
                                fontSize: '0.75rem',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              }}>
                              ATTENTION: TICKET OVER 30 MINUTES OLD
                            </Box>
                          )}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                          }}>
                          <Typography
                            variant='h6'
                            gutterBottom
                            sx={{
                              maxWidth: '70%',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}>
                            {ticket.title}
                          </Typography>
                          <Box>
                            {ticket.ticket_closed ? (
                              <Typography
                                variant='body2'
                                sx={{ color: 'error.main' }}>
                                CLOSED
                              </Typography>
                            ) : ticket.in_work ? (
                              <Typography
                                variant='body2'
                                sx={{ color: 'success.main' }}>
                                IN PROGRESS
                              </Typography>
                            ) : (
                              <Typography
                                variant='body2'
                                sx={{ color: 'warning.main' }}>
                                NEW
                              </Typography>
                            )}
                          </Box>
                        </Box>

                        <Typography
                          variant='body2'
                          color='text.secondary'
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}>
                          Client: {ticket.client_name}
                          {ticket.client_number &&
                            ` (${ticket.client_number})`}{' '}
                          {!ticket.ticket_closed && (
                            <Box
                              component='span'
                              sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                color:
                                  isTicketOld(ticket.created_at) &&
                                  !ticket.ticket_closed
                                    ? 'error.main'
                                    : 'text.secondary',
                                fontWeight:
                                  isTicketOld(ticket.created_at) &&
                                  !ticket.ticket_closed
                                    ? 'bold'
                                    : 'normal',
                              }}>
                              ⏱️{' '}
                              {elapsedTimes[ticket.id] ||
                                formatTimeDifference(ticket.created_at)}
                            </Box>
                          )}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          Created by: {ticket.commis_name}
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                          Date:{' '}
                          {new Date(ticket.created_at).toLocaleDateString()}
                        </Typography>

                        {ticket.ticket_closed && ticket.ticket_closed_by && (
                          <Typography variant='body2' color='text.secondary'>
                            Closed by: {ticket.ticket_closed_by}
                          </Typography>
                        )}
                        {/* Add the items section here */}
                        {ticket.items && ticket.items.length > 0 && (
                          <Box sx={{ mt: 1 }}>
                            <Typography
                              variant='body2'
                              sx={{ fontWeight: 'bold' }}>
                              Items:
                            </Typography>
                            <Box component='ul' sx={{ pl: 2, mt: 0.5, mb: 0 }}>
                              {ticket.items.map((item, idx) => (
                                <Box
                                  component='li'
                                  key={idx}
                                  sx={{
                                    fontSize: '0.875rem',
                                    color: 'text.secondary',
                                  }}>
                                  {item.name} (x{item.quantity})
                                  {item.description && ` - ${item.description}`}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}

                        {ticket.comments && (
                          <Box sx={{ mt: 1 }}>
                            <Typography
                              variant='body2'
                              sx={{
                                maxHeight: '60px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                              }}>
                              <strong>Comments:</strong> {ticket.comments}
                            </Typography>
                          </Box>
                        )}
                      </CardContent>

                      <Divider />

                      <Box sx={{ p: 2, mt: 'auto' }}>
                        {!ticket.ticket_closed && (
                          <>
                            <FormControlLabel
                              control={
                                <Switch
                                  size='small'
                                  checked={ticket.in_work}
                                  onChange={() =>
                                    handleToggleInWork(
                                      ticket.id,
                                      ticket.in_work
                                    )
                                  }
                                />
                              }
                              label='In Work'
                            />
                            <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                              {hasClosePermission && (
                                <>
                                  <Button
                                    variant='outlined'
                                    size='small'
                                    onClick={() =>
                                      handleOpenCommentDialog(ticket)
                                    }
                                    fullWidth>
                                    Comment
                                  </Button>
                                  <Button
                                    variant='outlined'
                                    size='small'
                                    color='primary'
                                    onClick={() =>
                                      handleOpenUpdateTitleDialog(ticket)
                                    }
                                    fullWidth>
                                    Title
                                  </Button>
                                  <Button
                                    variant='outlined'
                                    size='small'
                                    color='error'
                                    onClick={() => handleCloseTicket(ticket.id)}
                                    fullWidth>
                                    Close
                                  </Button>
                                </>
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Paper>
      </Box>
      {/* Update Title Dialog */}
      <Dialog
        open={updateTitleDialogOpen}
        onClose={() => setUpdateTitleDialogOpen(false)}>
        <DialogTitle>Update Ticket Title</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the title for ticket #{selectedTicketForUpdate?.id}
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            label='New Title'
            fullWidth
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateTitleDialogOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleUpdateTitle} color='primary'>
            Update Title
          </Button>
        </DialogActions>
      </Dialog>
      {/* Create Ticket Dialog */}

      <Dialog
        open={createDialogOpen}
        onClose={handleCloseCreateDialog}
        fullWidth
        maxWidth='md'
        scroll='paper'>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            Create New Ticket
            <IconButton onClick={handleCloseCreateDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label='Ticket Title'
                  name='title'
                  value={ticketData.title}
                  onChange={handleInputChange}
                  margin='normal'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label='Client Name'
                  name='client_name'
                  value={ticketData.client_name}
                  onChange={handleInputChange}
                  margin='normal'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label='Client Number'
                  name='client_number'
                  value={ticketData.client_number}
                  onChange={handleInputChange}
                  margin='normal'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label='Created By'
                  name='commis_name'
                  value={ticketData.commis_name}
                  onChange={handleInputChange}
                  margin='normal'
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  Items
                </Typography>
                <Divider sx={{ mb: 2 }} />

                {/* Items List */}
                {ticketData.items.map((item, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Paper variant='outlined' sx={{ p: 2 }}>
                      <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label='Item Name'
                            value={item.name}
                            onChange={(e) =>
                              handleItemChange(index, 'name', e.target.value)
                            }
                            size='small'
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <TextField
                            fullWidth
                            label='Quantity'
                            type='number'
                            value={item.quantity}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                'quantity',
                                e.target.value
                              )
                            }
                            size='small'
                            InputProps={{ inputProps: { min: 1 } }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <IconButton
                            color='error'
                            onClick={() => removeItemRow(index)}
                            disabled={ticketData.items.length <= 1}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label='Description (Optional)'
                            value={item.description}
                            onChange={(e) =>
                              handleItemChange(
                                index,
                                'description',
                                e.target.value
                              )
                            }
                            size='small'
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Box>
                ))}

                <Button
                  variant='outlined'
                  startIcon={<AddIcon />}
                  onClick={addItemRow}
                  sx={{ mt: 1, mb: 2 }}>
                  Add Another Item
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCreateDialog}>Cancel</Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSubmit}
            disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Create Ticket'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Comment Dialog */}
      <Dialog
        open={commentDialogOpen}
        onClose={() => setCommentDialogOpen(false)}>
        <DialogTitle>Add Comment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add a comment to ticket: {selectedTicket?.title}
          </DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            label='Comment'
            fullWidth
            multiline
            rows={4}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCommentDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAddComment} color='primary'>
            Add Comment
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Snackbar */}
      <Snackbar
        open={alertInfo.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert
          onClose={handleCloseAlert}
          severity={alertInfo.severity}
          sx={{ width: '100%' }}>
          {alertInfo.message}
        </Alert>
      </Snackbar>

      {/* Floating Action Button for mobile */}
      <Box
        sx={{
          display: { xs: 'block', sm: 'none' },
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 1000,
        }}>
        <Tooltip title='Create New Ticket'>
          <Fab color='primary' onClick={handleOpenCreateDialog}>
            <AddIcon />
          </Fab>
        </Tooltip>
      </Box>
    </Container>
  );
};

export default TicketView;
