import React from 'react';
import {
    Paper,
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    Tooltip,
    Fab
} from '@mui/material';
import { DirectionsCar, Close, PeopleAlt } from '@mui/icons-material';

const LocationsPanel = ({ locations, onClose, isOpen, onOpen }) => {
    return (
        <>
            {isOpen ? (
                <Paper
                    elevation={3}
                    sx={{
                        position: 'absolute',
                        bottom: '20px',
                        right: '20px',
                        width: '300px',
                        maxHeight: '80vh',
                        overflow: 'auto',
                        zIndex: 1000,
                        backgroundColor: 'rgba(255, 255, 255, 0.95)'
                    }}
                >
                    <Box sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography variant="h6" component="h2">
                                Liste Chauffeur
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={onClose}
                                sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                            >
                                <Close />
                            </IconButton>
                        </Box>

                        <List sx={{ p: 0 }}>
                            {locations.map((driver) => (
                                <ListItem
                                    key={driver.id}
                                    sx={{
                                        mb: 1,
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        borderRadius: 1,
                                        '&:last-child': { mb: 0 }
                                    }}
                                >
                                    <ListItemIcon sx={{ minWidth: '40px' }}>
                                        <DirectionsCar sx={{ color: driver.color }} />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={
                                            <Typography variant="subtitle1" component="div">
                                                {driver.username}
                                            </Typography>
                                        }
                                        secondary={
                                            <Tooltip title="Current coordinates" placement="bottom-start">
                                                <Typography variant="caption" component="div" sx={{ fontFamily: 'monospace' }}>
                                                    {`vers ${driver.client_name}. `}
                                                </Typography>
                                                <Typography variant="caption" component="div" sx={{ fontFamily: 'monospace' }}>
                                                    {`${driver.pending_orders_count} livraisons restantes`}
                                                </Typography>
                                            </Tooltip>
                                        }
                                    />
                                    <Typography
                                        variant="caption"
                                        color="text.secondary"
                                        sx={{
                                            position: 'absolute',
                                            bottom: '4px',
                                            right: '8px'
                                        }}
                                    >
                                        → {driver.destination}
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Paper>
            ) : (
                <Tooltip title="Liste Chauffeur">
                    <Fab
                        color="primary"
                        onClick={onOpen}
                        sx={{
                            position: 'absolute',
                            bottom: '20px',
                            right: '20px',
                            zIndex: 1000
                        }}
                    >
                        <PeopleAlt />
                    </Fab>
                </Tooltip>
            )}
        </>
    );
};

export default LocationsPanel;