import React, { useEffect, useState } from 'react';
import {
  getLocationCountsByUser,
  getPickedItemCountsByUser,
  getLocationDetailsByUser,
  getPickedItemDetailsByUser,
} from '../controller/PickerStatsController';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
} from '@mui/material';
import LocationDetailsModal from '../views/locationDetailsModal';
import ClickableStatsCell from '../views/clickableStatsCell';

const UserLocationStats = () => {
  const [locationCounts, setLocationCounts] = useState({});
  const [pickedCounts, setPickedCounts] = useState({});
  const [error, setError] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    title: '',
    loading: false,
    data: [],
    username: '',
    timePeriod: '',
    type: '',
    expectedCount: 0, // Added to track the expected number of records
  });

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const locationData = await getLocationCountsByUser();
        setLocationCounts(locationData);
      } catch (err) {
        setError('Failed to fetch location counts.');
      }
    };

    const fetchPickedData = async () => {
      try {
        const pickedData = await getPickedItemCountsByUser();
        setPickedCounts(pickedData);
      } catch (err) {
        setError('Failed to fetch picked item counts.');
      }
    };

    fetchLocationData();
    fetchPickedData();
  }, []);

  const handleLocationClick = async (username, timePeriod, expectedCount) => {
    setModal({
      open: true,
      title: 'Location Details',
      loading: true,
      data: [],
      username,
      timePeriod,
      type: 'location',
      expectedCount, // Store the count from the statistics table
    });

    try {
      const response = await getLocationDetailsByUser(username, timePeriod);
      console.log(
        `Expected ${expectedCount} records for ${username} - ${timePeriod}`
      );

      // Get location details from the response
      const allDetails =
        response && response.location_details ? response.location_details : [];

      // Only show the expected number of records
      const filteredDetails = allDetails.slice(0, expectedCount);

      console.log(
        `Received ${allDetails.length} records, displaying ${filteredDetails.length}`
      );

      setModal((prev) => ({
        ...prev,
        loading: false,
        data: filteredDetails,
      }));
    } catch (err) {
      console.error('Error fetching location details:', err);
      setModal((prev) => ({
        ...prev,
        loading: false,
        data: [],
      }));
      // Display error alert to user
      setError(`Failed to fetch location details: ${err.message}`);
      // Reset error after 5 seconds
      setTimeout(() => setError(null), 5000);
    }
  };

  const handlePickedClick = async (username, timePeriod, expectedCount) => {
    setModal({
      open: true,
      title: 'Picked Items Details',
      loading: true,
      data: [],
      username,
      timePeriod,
      type: 'picked',
      expectedCount, // Store the count from the statistics table
    });

    try {
      const response = await getPickedItemDetailsByUser(username, timePeriod);
      console.log(
        `Expected ${expectedCount} records for ${username} - ${timePeriod}`
      );

      // Get item details from the response
      const allDetails =
        response && response.item_details ? response.item_details : [];

      // Only show the expected number of records
      const filteredDetails = allDetails.slice(0, expectedCount);

      console.log(
        `Received ${allDetails.length} records, displaying ${filteredDetails.length}`
      );

      setModal((prev) => ({
        ...prev,
        loading: false,
        data: filteredDetails,
      }));
    } catch (err) {
      console.error('Error fetching picked item details:', err);
      setModal((prev) => ({
        ...prev,
        loading: false,
        data: [],
      }));
      // Display error alert to user
      setError(`Failed to fetch picked item details: ${err.message}`);
      // Reset error after 5 seconds
      setTimeout(() => setError(null), 5000);
    }
  };

  const handleCloseModal = () => {
    setModal((prev) => ({
      ...prev,
      open: false,
    }));
  };

  return (
    <Container
      maxWidth='lg'
      sx={{ padding: 8, width: '100%', margin: '0 auto' }}>
      <Typography
        variant='h4'
        style={{
          color: 'var(--h1)',
          marginBottom: '20px',
          textAlign: 'center',
          fontWeight: 'bold',
        }}>
        Users Stats
      </Typography>

      {error ? (
        <Paper
          elevation={2}
          sx={{
            p: 2,
            mb: 3,
            bgcolor: '#ffebee',
            border: '1px solid #f44336',
            borderRadius: 1,
          }}>
          <Typography
            color='error'
            variant='body1'
            align='center'
            fontWeight='medium'>
            {error}
          </Typography>
        </Paper>
      ) : (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={3}
          justifyContent='center'
          alignItems='stretch'>
          <TableContainer component={Paper} elevation={3} sx={{ flex: 1 }}>
            <Typography
              variant='h6'
              style={{
                padding: '16px',
                backgroundColor: '#f0f0f0',
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'var(--h1)',
              }}>
              Location Counts by User
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='left'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Usager
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Total
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Aujourd'hui
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Dernier 30 jours
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    31 à 60 jours
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(locationCounts).map(([user, stats]) => (
                  <TableRow key={user}>
                    <TableCell align='left' style={{ color: '#3f51b5' }}>
                      {user}
                    </TableCell>
                    <ClickableStatsCell
                      value={stats.total_location_count}
                      onClick={() =>
                        handleLocationClick(
                          user,
                          'all',
                          stats.total_location_count
                        )
                      }
                    />
                    <ClickableStatsCell
                      value={stats.today_count}
                      onClick={() =>
                        handleLocationClick(user, 'today', stats.today_count)
                      }
                    />
                    <ClickableStatsCell
                      value={stats.last_30_days_count}
                      onClick={() =>
                        handleLocationClick(
                          user,
                          'last_30_days',
                          stats.last_30_days_count
                        )
                      }
                    />
                    <ClickableStatsCell
                      value={stats.days_31_to_60_count}
                      onClick={() =>
                        handleLocationClick(
                          user,
                          '31_to_60_days',
                          stats.days_31_to_60_count
                        )
                      }
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} elevation={3} sx={{ flex: 1 }}>
            <Typography
              variant='h6'
              style={{
                padding: '16px',
                backgroundColor: '#f0f0f0',
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'var(--h1)',
              }}>
              Picked Items by User
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    align='left'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Usager
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Total
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Aujourd'hui
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    Last 30 Days
                  </TableCell>
                  <TableCell
                    align='right'
                    style={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                    31 à 60 jours
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(pickedCounts).map(([user, stats]) => (
                  <TableRow key={user}>
                    <TableCell align='left' style={{ color: '#3f51b5' }}>
                      {user}
                    </TableCell>
                    <ClickableStatsCell
                      value={stats.total_pick_count}
                      onClick={() =>
                        handlePickedClick(user, 'all', stats.total_pick_count)
                      }
                    />
                    <ClickableStatsCell
                      value={stats.today_count}
                      onClick={() =>
                        handlePickedClick(user, 'today', stats.today_count)
                      }
                    />
                    <ClickableStatsCell
                      value={stats.last_30_days_count}
                      onClick={() =>
                        handlePickedClick(
                          user,
                          'last_30_days',
                          stats.last_30_days_count
                        )
                      }
                    />
                    <ClickableStatsCell
                      value={stats.days_31_to_60_count}
                      onClick={() =>
                        handlePickedClick(
                          user,
                          '31_to_60_days',
                          stats.days_31_to_60_count
                        )
                      }
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}

      <LocationDetailsModal
        open={modal.open}
        onClose={handleCloseModal}
        title={modal.title}
        loading={modal.loading}
        data={modal.data}
        username={modal.username}
        timePeriod={modal.timePeriod}
        type={modal.type}
        expectedCount={modal.expectedCount}
      />
    </Container>
  );
};

export default UserLocationStats;
