import React, { useCallback, useEffect, useState, useRef } from 'react';
import LocationsPanel from '../components/LocationPanel';
import NotificationsPanel from '../components/NotificationPanel';
import { getStore } from '../components/GetStore';
import LocationMapController from '../controller/LocationMapController';
import '../styles/map.css';
import '../styles/animations.css';
import {
  CarIcon,
  ClientIcon,
  WarehouseIcon,
  HouseIcon,
} from '../components/MapIcons';

const LocationMap = () => {
  const [map, setMap] = useState(null);
  const markersRef = useRef({});
  const driverLastUpdatedRef = useRef({});
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [drivers, setDrivers] = useState([]);
  const [pickup, setPickup] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const toastTimeoutRef = useRef(null);

  // Set the inactivity threshold to 10 minutes (in milliseconds)
  const INACTIVITY_THRESHOLD = 10 * 60 * 1000;

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const getCSSVariable = (variableName) => {
    return getComputedStyle(document.documentElement)
      .getPropertyValue(variableName)
      .trim();
  };

  const storeId = getStore();
  const primaryColor = getCSSVariable('--blue') || '#ff0000';

  const [shops] = useState([
    {
      id: 1,
      name: 'Saint Hubert',
      lat: 45.4877180973376,
      lng: -73.3844766885022,
      color: primaryColor,
    },
    {
      id: 2,
      name: 'Saint Jean',
      lat: 45.330504849876874,
      lng: -73.29472113083476,
      color: primaryColor,
    },
    {
      id: 3,
      name: 'Châteauguay',
      lat: 45.35058887654516,
      lng: -73.68901625596571,
      color: primaryColor,
    },
  ]);

  const fetchDriverData = useCallback(async () => {
    try {
      const data = await LocationMapController.getDriversLocation(storeId);

      setDrivers(data.location);
      setPickup(data.pickup);
      setNotifications(data.notifications);
      setError(null);

      // Update last seen timestamps for each driver in the data
      const now = Date.now();
      data.location.forEach((driver) => {
        driverLastUpdatedRef.current[driver.driver_id.toString()] = now;
      });
    } catch (err) {
      setError('Error loading driver data. Please try again later.');
      console.error('Error fetching driver data:', err);
    } finally {
      setLoading(false);
    }
  }, [storeId]);

  // Toast notification function
  const showToast = useCallback((message, type = 'info') => {
    // Clear any existing toast timeout
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
    }

    // Remove existing toast if any
    const existingToast = document.querySelector('.map-toast');
    if (existingToast) {
      existingToast.remove();
    }

    // Create new toast
    const toast = document.createElement('div');
    toast.className = `map-toast map-toast-${type}`;
    toast.innerHTML = message;
    document.body.appendChild(toast);

    // Show the toast with animation
    setTimeout(() => {
      toast.classList.add('show');

      // Auto-hide after a delay
      toastTimeoutRef.current = setTimeout(() => {
        toast.classList.remove('show');
        setTimeout(() => {
          if (toast.parentNode) {
            document.body.removeChild(toast);
          }
        }, 300);
      }, 3000);
    }, 100);
  }, []);

  // Handle notification drag from Material UI panel
  const handleNotificationDragEnd = useCallback(
    async ({ notification, position, event }) => {
      // Find if a driver marker was under the drop
      const droppedOnElement = document.elementFromPoint(
        event.clientX,
        event.clientY
      );
      if (!droppedOnElement) return;

      // Find the closest SVG element that has a data-driver-id attribute
      const driverMarker =
        droppedOnElement.closest('.car-icon') ||
        droppedOnElement.closest('[data-driver-id]');

      if (!driverMarker) {
        showToast(
          'Veuillez déposer sur un conducteur pour assigner le ramassage',
          'warning'
        );
        return;
      }

      const driverId = driverMarker.getAttribute('data-driver-id');
      if (!driverId) return;

      // Find driver object
      const driver = drivers.find((d) => d.driver_id.toString() === driverId);
      if (!driver) return;

      // Apply visual feedback
      driverMarker.setAttribute('data-drop', 'true');
      setTimeout(() => driverMarker.removeAttribute('data-drop'), 500);

      try {
        // Show loading toast
        showToast(`Attribution du ramassage à ${driver.username}...`, 'info');

        // Call API to create pickup order
        await LocationMapController.createPickupOrder(
          driver.driver_id,
          notification.id
        );

        // Success toast
        showToast(
          `Ramassage assigné avec succès à ${driver.username}`,
          'success'
        );

        // Refresh data
        await fetchDriverData();
      } catch (err) {
        console.error('Error assigning pickup:', err);
        setError(
          "Erreur lors de l'attribution du ramassage. Veuillez réessayer plus tard."
        );
        showToast(
          `Échec de l'attribution du ramassage: ${err.message}`,
          'error'
        );
      }
    },
    [drivers, fetchDriverData, showToast]
  );

  // Global drag events for the map container
  useEffect(() => {
    const mapContainer = document.querySelector('.map-container');
    if (!mapContainer) return;

    const handleDragEnter = () => {
      setDragActive(true);
    };

    const handleDragLeave = (e) => {
      // Only set to false if we're leaving the entire container
      if (e.currentTarget.contains(e.relatedTarget)) return;
      setDragActive(false);
    };

    const handleDragOver = (e) => {
      e.preventDefault(); // Necessary to allow drop
      e.dataTransfer.dropEffect = 'move';
    };

    const handleDrop = (e) => {
      e.preventDefault();
      setDragActive(false);
    };

    mapContainer.addEventListener('dragenter', handleDragEnter);
    mapContainer.addEventListener('dragleave', handleDragLeave);
    mapContainer.addEventListener('dragover', handleDragOver);
    mapContainer.addEventListener('drop', handleDrop);

    return () => {
      mapContainer.removeEventListener('dragenter', handleDragEnter);
      mapContainer.removeEventListener('dragleave', handleDragLeave);
      mapContainer.removeEventListener('dragover', handleDragOver);
      mapContainer.removeEventListener('drop', handleDrop);
    };
  }, []);

  /*
   *  Map initialization
   */
  useEffect(() => {
    let mapInstance = null;
    let scriptElement = null;
    let linkElement = null;
    let intervalId = null;
    let markerCleanupIntervalId = null;
    let isMounted = true;

    const initializeMap = () => {
      if (!window.L || !isMounted) return;

      console.log('Initializing Leaflet map');

      try {
        mapInstance = window.L.map('map', {
          minZoom: 10,
          maxZoom: 14,
          zoomControl: true,
        }).setView([45.4877180973376, -73.3844766885022], 11);

        window.L.tileLayer(
          'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
          {
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          }
        ).addTo(mapInstance);

        // Force a map refresh
        setTimeout(() => {
          if (mapInstance && isMounted) {
            mapInstance.invalidateSize();
          }
        }, 100);

        if (isMounted) {
          setMap(mapInstance);
        }
      } catch (error) {
        console.error('Error initializing map:', error);
      }
    };

    // Function to clean up inactive markers
    const cleanupInactiveMarkers = () => {
      const now = Date.now();
      Object.entries(driverLastUpdatedRef.current).forEach(
        ([driverId, lastUpdated]) => {
          // If marker has been inactive for more than INACTIVITY_THRESHOLD
          if (now - lastUpdated > INACTIVITY_THRESHOLD) {
            // Remove marker from map
            if (markersRef.current[driverId]) {
              markersRef.current[driverId].remove();
              delete markersRef.current[driverId];
            }
            // Remove from lastUpdated tracking
            delete driverLastUpdatedRef.current[driverId];
          }
        }
      );
    };

    // Add Leaflet CSS
    try {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href =
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/leaflet.css';
      document.head.appendChild(link);
      linkElement = link;

      // Add Leaflet JS
      const script = document.createElement('script');
      script.src =
        'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.9.4/leaflet.js';
      script.async = false;

      script.onload = () => {
        if (isMounted) {
          initializeMap();
        }
      };

      document.body.appendChild(script);
      scriptElement = script;

      // Start data polling
      fetchDriverData();
      intervalId = setInterval(fetchDriverData, 10000);

      // Start cleanup interval - check once per minute for inactive markers
      markerCleanupIntervalId = setInterval(cleanupInactiveMarkers, 60000);
    } catch (error) {
      console.error('Error setting up Leaflet:', error);
    }

    // Cleanup function
    return () => {
      isMounted = false;

      // Clear all intervals
      if (intervalId) {
        clearInterval(intervalId);
      }
      if (markerCleanupIntervalId) {
        clearInterval(markerCleanupIntervalId);
      }

      // Clear toast timeout if exists
      if (toastTimeoutRef.current) {
        clearTimeout(toastTimeoutRef.current);
      }

      // Clean up map
      if (mapInstance) {
        try {
          mapInstance.remove();
        } catch (error) {
          console.error('Error removing map:', error);
        }
      }

      // Remove script and link elements
      try {
        if (scriptElement?.parentNode) {
          scriptElement.parentNode.removeChild(scriptElement);
        }
        if (linkElement?.parentNode) {
          linkElement.parentNode.removeChild(linkElement);
        }
      } catch (error) {
        console.error('Error cleaning up Leaflet resources:', error);
      }
    };
  }, [fetchDriverData, INACTIVITY_THRESHOLD]);

  /*
   * Show the driver's locations and destination
   */
  useEffect(() => {
    if (!map || !window.L) return;
    const L = window.L;
    const clientMarkersRef = {};

    // Create HTML tooltip for the driver
    const createTooltip = (driver) => {
      const div = document.createElement('div');
      div.style.textAlign = 'center';

      // Format the driver name
      const driverNameEl = document.createElement('div');
      driverNameEl.innerHTML = `<b>${driver.username}</b>`;
      driverNameEl.style.fontWeight = 'bold';
      driverNameEl.style.fontSize = '12px';

      // Add the pending orders count
      const orderCountEl = document.createElement('div');
      orderCountEl.style.fontSize = '11px';
      orderCountEl.textContent = `(${driver.pending_orders_count})`;

      // Add client name or "Retour" for return to store
      const clientInfoEl = document.createElement('div');
      clientInfoEl.style.fontSize = '11px';
      clientInfoEl.style.fontStyle = 'italic';
      clientInfoEl.style.color = '#555';
      clientInfoEl.textContent =
        driver.client_name !== '' ? driver.client_name : 'Retour';

      // Assemble the tooltip
      div.appendChild(driverNameEl);
      div.appendChild(orderCountEl);
      div.appendChild(clientInfoEl);

      return div;
    };

    // Enhanced drag and drop event handling for driver markers
    const setupDragEvents = (element, driver) => {
      // Set data attribute for driver identification
      element.setAttribute('data-driver-id', driver.driver_id);

      const events = {
        dragenter: (e) => {
          e.preventDefault();
          element.setAttribute('data-dragover', 'true');
        },

        dragover: (e) => {
          e.preventDefault();
          e.dataTransfer.dropEffect = 'move';
        },

        dragleave: (e) => {
          // Only remove attribute if actually leaving this element
          if (e.currentTarget.contains(e.relatedTarget)) return;
          element.removeAttribute('data-dragover');
        },

        drop: (e) => {
          e.preventDefault();
          e.stopPropagation(); // Prevent map container from handling the drop

          element.removeAttribute('data-dragover');
          element.setAttribute('data-drop', 'true');

          try {
            // Parse the dragged notification data
            const data = JSON.parse(e.dataTransfer.getData('application/json'));

            // Process the drop
            if (data.id) {
              // Call API to assign pickup to driver
              showToast(
                `Assignation du ramassage à ${driver.username}...`,
                'info'
              );

              LocationMapController.createPickupOrder(driver.driver_id, data.id)
                .then(() => {
                  showToast(
                    `Ramassage assigné à ${driver.username}`,
                    'success'
                  );
                  fetchDriverData(); // Refresh data
                })
                .catch((error) => {
                  console.error('Error assigning pickup:', error);
                  showToast(`Erreur: ${error.message}`, 'error');
                });
            }

            // Reset the drop visual after animation
            setTimeout(() => element.removeAttribute('data-drop'), 500);
          } catch (error) {
            console.error('Error handling drop:', error);
            showToast(`Erreur: ${error.message}`, 'error');
            setTimeout(() => element.removeAttribute('data-drop'), 500);
          }
        },
      };

      // Add event listeners
      Object.entries(events).forEach(([event, handler]) => {
        element.addEventListener(event, handler);
      });

      // Store the event handlers for cleanup
      element.dragEvents = events;
    };

    drivers.forEach((driver) => {
      const driverId = driver.driver_id.toString();
      const { client_latitude, client_longitude, latitude, longitude } = driver;

      // Create client marker if coordinates exist
      const clientMarker =
        client_latitude && client_longitude
          ? L.marker([client_latitude, client_longitude], {
              icon: L.divIcon(ClientIcon()),
            })
          : null;

      if (clientMarker) clientMarkersRef[driverId] = clientMarker;

      const color =
        driver.order_number.includes('STORE_RETURN') || !driver.order_number
          ? '#57ff00'
          : '#ff2800';

      // Update existing marker or create new one
      if (markersRef.current[driverId]) {
        // Update marker position
        markersRef.current[driverId].setLatLng([latitude, longitude]);

        // Update icon (in case status changed)
        markersRef.current[driverId].setIcon(L.divIcon(CarIcon(color)));

        // Update tooltip content
        if (markersRef.current[driverId].getTooltip()) {
          markersRef.current[driverId].setTooltipContent(createTooltip(driver));
        }

        // Update the drag event driver data
        const svgElement = markersRef.current[driverId]
          .getElement()
          ?.querySelector('svg');
        if (svgElement) {
          // Remove old event listeners if they exist
          if (svgElement.dragEvents) {
            Object.entries(svgElement.dragEvents).forEach(
              ([event, handler]) => {
                svgElement.removeEventListener(event, handler);
              }
            );
          }

          // Set up new event listeners
          setupDragEvents(svgElement, driver);
        }
      } else {
        // Create new marker
        const marker = L.marker([latitude, longitude], {
          icon: L.divIcon(CarIcon(color)),
        }).addTo(map);

        marker.riseOnHover = true;

        // Set up hover events
        if (clientMarker) {
          marker.on('mouseover', () => {
            Object.values(clientMarkersRef).forEach((m) => m?.remove());
            clientMarker.addTo(map);
          });
          marker.on('mouseout', () => clientMarker.remove());
        }

        // Set up drag and drop
        const svgElement = marker.getElement()?.querySelector('svg');
        if (svgElement) {
          setupDragEvents(svgElement, driver);
        }

        // Add tooltip
        marker.bindTooltip(createTooltip(driver), {
          permanent: true,
          direction: 'bottom',
          offset: [0, 20],
          className: 'driver-tooltip',
          opacity: 0.8,
        });

        markersRef.current[driverId] = marker;
      }
    });

    // Cleanup on unmount
    return () => {
      Object.values(clientMarkersRef).forEach((marker) => marker?.remove());
    };
  }, [drivers, map, showToast, fetchDriverData]);

  /*
   * Show the Pickup locations
   */
  useEffect(() => {
    if (!map || !window.L) return;
    const L = window.L;

    // Initialize markers map if it doesn't exist
    const markers = new Map();

    // Remove existing markers
    markers.forEach((marker) => marker.remove());
    markers.clear();

    // Add new markers
    pickup.forEach((location) => {
      if (
        (!location.lat && !location.long) ||
        markers.has(`${location.lat}-${location.long}`)
      )
        return;

      const marker = L.marker([location.lat, location.long], {
        icon: L.divIcon(WarehouseIcon('#f44336')),
      }).addTo(map);

      marker.riseOnHover = true;

      const text = `${location.name}`;

      marker.bindTooltip(text, {
        direction: 'bottom',
        offset: [16, 32],
        class: 'label',
      });

      markers.set(`${location.lat}-${location.long}`, marker);
    });

    // Cleanup function
    return () => {
      markers.forEach((marker) => marker.remove());
      markers.clear();
    };
  }, [pickup, map]);

  /*
   *  Show the shop locations
   */
  useEffect(() => {
    if (!map || !window.L) return;
    const L = window.L;

    // Add markers
    shops.forEach((location) => {
      const marker = L.marker([location.lat, location.lng], {
        icon: L.divIcon(HouseIcon(location.color)),
      }).addTo(map);

      marker.riseOnHover = true;

      marker.bindTooltip(location.name, {
        direction: 'bottom',
        offset: [0, 12],
        class: 'label',
      });
    });
  }, [shops, map]);

  /*
   * 	Draw HTML
   */
  return (
    <>
      <NotificationsPanel
        notifications={notifications}
        isOpen={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        onNotificationDragEnd={handleNotificationDragEnd}
      />
      <LocationsPanel
        locations={drivers}
        isOpen={isPanelOpen}
        onClose={() => setIsPanelOpen(false)}
        onOpen={() => setIsPanelOpen(true)}
      />
      <div className={`map-container ${dragActive ? 'drag-active' : ''}`}>
        <div id='map' />
        {error && <div className='error-toast'>{error}</div>}
        {loading && (
          <div className='map-loading'>Chargement de la carte...</div>
        )}
      </div>
    </>
  );
};

export default LocationMap;
