import axios from 'axios';
import { getStore } from '../components/GetStore';

const API_URL = `${process.env.REACT_APP_API_URL}`;
const X_DISPATCH_KEY =
  'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj';

const headers = {
  'Content-Type': 'application/json',
  'X-Dispatch-key': X_DISPATCH_KEY,
};

const fetchData = async (endpoint, data) => {
  try {
    console.log(`Calling API: ${endpoint} with data:`, data);
    const response = await axios.post(`${API_URL}${endpoint}`, data, {
      headers,
    });
    console.log(`API response from ${endpoint}:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${endpoint}:`, error);
    console.error(`Error details:`, error.response?.data || error.message);
    throw error;
  }
};

export const getLocationCountsByUser = async () => {
  const storeId = await getStore();
  return fetchData('/misc_dispatch/v2/count_by_user', { store: storeId });
};

export const getPickedItemCountsByUser = async () => {
  const storeId = await getStore();
  return fetchData('/misc_dispatch/v2/picked_count', { store: storeId });
};

export const getLocationDetailsByUser = async (username, timePeriod) => {
  return fetchData('/misc_dispatch/v2/location_details_by_user', {
    username,
    time_period: timePeriod,
  });
};

export const getPickedItemDetailsByUser = async (username, timePeriod) => {
  return fetchData('/misc_dispatch/v2/picked_items_by_user', {
    username,
    time_period: timePeriod,
  });
};
