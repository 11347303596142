import axios from 'axios';
import { getStore } from '../components/GetStore';

const API_URL = `${process.env.REACT_APP_API_URL}`;
const X_DISPATCH_KEY =
  'cHVibGljbW91c2VyZWFkdHVybndvcmRzdHJhbmdlcmNvYWNocmVjb3JkdHJvcGljYWxicmFzc3N0b25lYXNsZWVwb3RoZXJ3b3JlZXhj';

const headers = {
  'Content-Type': 'application/json',
  'X-Dispatch-key': X_DISPATCH_KEY,
};

export const fetchDeliveryStatsByStore = async () => {
  try {
    const storeId = await getStore();
    if (!storeId) throw new Error('Store ID is not available');

    const response = await axios.post(
      `${API_URL}/misc_dispatch/v2/delivery_stats_by_store`,
      { storeId: storeId },
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching delivery stats by store:', error);
    throw error;
  }
};

export const fetchDeliveryCountsByDateRange = async (startDate, endDate) => {
  try {
    const storeId = await getStore();
    if (!storeId) throw new Error('Store ID is not available');

    const formattedStartDate =
      startDate instanceof Date
        ? startDate.toISOString().split('T')[0]
        : startDate;
    const formattedEndDate =
      endDate instanceof Date ? endDate.toISOString().split('T')[0] : endDate;

    const response = await axios.post(
      `${API_URL}/misc_dispatch/v2/delivery_counts_by_date_range`,
      {
        storeId: storeId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      },
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching delivery counts by date range:', error);
    throw error;
  }
};

export const fetchOrdersByTimeCategory = async (
  timeCategory,
  storeId = null,
  startDate = null,
  endDate = null
) => {
  try {
    // If no storeId is provided, get the current store
    const currentStoreId = storeId || (await getStore());
    if (!currentStoreId) throw new Error('Store ID is not available');

    // Format dates if they're Date objects
    const formattedStartDate =
      startDate instanceof Date
        ? startDate.toISOString().split('T')[0]
        : startDate;

    const formattedEndDate =
      endDate instanceof Date ? endDate.toISOString().split('T')[0] : endDate;

    // Prepare request data
    const requestData = {
      timeCategory: timeCategory,
      storeId: currentStoreId,
    };

    // Add date filters if provided
    if (formattedStartDate && formattedEndDate) {
      requestData.startDate = formattedStartDate;
      requestData.endDate = formattedEndDate;
    }

    const response = await axios.post(
      `${API_URL}/misc_dispatch/v2/orders_by_time_category`,
      requestData,
      { headers }
    );

    return response.data;
  } catch (error) {
    console.error('Error in fetchOrdersByTimeCategory:', error);
    throw error;
  }
};

/**
 * Maps time category display name to backend query parameters
 * @param {string} displayName - Display name like "1-20 Minutes"
 * @returns {string} - Backend category identifier
 */
export const mapTimeCategoryToQueryParam = (displayName) => {
  const categoryMap = {
    '1-20 Minutes': '1-20',
    '21-40 Minutes': '21-40',
    '41-60 Minutes': '41-60',
    '60-90 Minutes': '60-90',
    '90+ Minutes': '90+',
  };

  return categoryMap[displayName] || '';
};
