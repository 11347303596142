import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Box,
} from '@mui/material';
import { format, differenceInMinutes } from 'date-fns';

const OrderDetailModal = ({ open, onClose, orders, category }) => {
  if (!orders) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
        <DialogTitle>
          <Typography variant='h6' component='div'>
            Orders in {category} category
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display='flex' justifyContent='center' p={3}>
            <CircularProgress />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
      <DialogTitle>
        <Typography variant='h6' component='div'>
          Orders in {category} category
        </Typography>
        <Typography variant='subtitle1' color='textSecondary'>
          {orders.length} orders found
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Order Number</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Delivered At</TableCell>
                <TableCell>Delivery Time</TableCell>
                <TableCell>Driver</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow key={order.order_number}>
                  <TableCell>{order.order_number}</TableCell>
                  <TableCell>{order.customer}</TableCell>
                  <TableCell>
                    {format(new Date(order.created_at), 'MMM dd, yyyy HH:mm')}
                  </TableCell>
                  <TableCell>
                    {format(new Date(order.delivered_at), 'MMM dd, yyyy HH:mm')}
                  </TableCell>
                  <TableCell>
                    {`${order.delivery_time_minutes} minutes`}
                  </TableCell>
                  <TableCell>{order.driver_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderDetailModal;
